const userLoggedInReducer = (state = false, action) => {
  switch (action.type) {
      case 'SET_USER_LOGGED_IN':
          return action.payload;

      default:
          return state;
  }
}

export default userLoggedInReducer;
