import { combineReducers } from 'redux';
import selectedFiatCurrencyReducer from './selectedFiatCurrency';
import fiatAmountReducer from './fiatAmount';
import fiatCurrencyListReducer from './fiatCurrencyList';
import cryptoCurrencyListReducer from './cryptoCurrencyList';
import selectedCryptoCurrencyReducer from './selectedCryptoCurrency';
import allowedFiatPaymentMethodsReducer from './allowedFiatPaymentMethods'
import priceDetailsReducer from './priceDetails';
import walletAddressReducer from './walletAddress';
import emailAddressReducer from './emailAddress';
import selectedPaymentMethodReducer from './selectedPaymentMethod';
import userProfile from './userProfile';
import orderId from './orderId';
import fiatListLoaded from './fiatListLoaded.js';
import cryptoListLoaded from './cryptoListLoaded';
import userAddress from './userAddress';
import minimumAmountError from './minimumAmountError';
import userLoggedIn from './userLoggedIn';
import partnerApiKey from './partnerApiKey';
import primaryColor from './primaryColor';
import isDarkTheme from './isDarkTheme';
import walletAddressMap from './walletAddressMap';
import firstTimePageLoaded from './firstTimePageLoaded';
import partnerOrderId from './partnerOrderId';
import selectedCryptoCurrencyToSell from './selectedCryptoCurrencyToSell';
import cryptoAmountToSell from './cryptoAmountToSell';
import selectedFiatCurrencyForSell from './selectedFiatCurrencyForSell';
import fiatAmountForSell from './fiatAmountForSell';
import priceDetailsForSell from './priceDetailsForSell';
import priceParamChanged from './priceParamChanged';
import transactionType from './transactionType';

const allReducers = combineReducers({
    fiatCurrencyList: fiatCurrencyListReducer,
    selectedFiatCurrency: selectedFiatCurrencyReducer,
    fiatAmount: fiatAmountReducer,
    cryptoCurrencyList: cryptoCurrencyListReducer,
    selectedCryptoCurrency: selectedCryptoCurrencyReducer,
    allowedFiatPaymentMethods: allowedFiatPaymentMethodsReducer,
    priceDetails: priceDetailsReducer,
    walletAddress: walletAddressReducer,
    emailAddress: emailAddressReducer,
    selectedPaymentMethod: selectedPaymentMethodReducer,
    userProfile: userProfile,
    orderId: orderId,
    fiatListLoaded,
    cryptoListLoaded,
    userAddress: userAddress,
    minimumAmountError: minimumAmountError,
    userLoggedIn: userLoggedIn,
    partnerApiKey: partnerApiKey,
    primaryColor: primaryColor,
    walletAddressMap: walletAddressMap,
    isDarkTheme: isDarkTheme,
    firstTimePageLoaded: firstTimePageLoaded,
    partnerOrderId: partnerOrderId,
    selectedCryptoCurrencyToSell: selectedCryptoCurrencyToSell,
    cryptoAmountToSell: cryptoAmountToSell,
    fiatAmountForSell: fiatAmountForSell,
    selectedFiatCurrencyForSell: selectedFiatCurrencyForSell,
    priceDetailsForSell: priceDetailsForSell,
    priceParamChanged: priceParamChanged,
    transactionType: transactionType,
});

export default allReducers;
