import { useEffect } from 'react'
import { useHistory } from "react-router-dom";
import { Box, Grid, FormLabel, IconButton, TextField, FormControl, Avatar, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import CurrencyService from '../lib/services/CurrencyService'
import lodash from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { setCryptoCurrencies, setCryptoListLoaded, setCryptoAmountToSell, setSelectedCryptoCurrency, setWalletAddress, setSelectedCryptoCurrencyToSell, setFiatAmountForSell, setPriceParamChanged } from '../actions';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import _ from 'lodash';
import { useTheme } from '@material-ui/core/styles';
import CustomTooltip from 'widgets/CustomTooltip';


const styles = theme => ({
  select: {
    marginTop: '7px',
    marginRight: '7px',
    paddingRight: '7px',
  },
  mainDiv: {
    marginBottom: "1em"
  },
  avatar: {
    width: '30px',
    height: '30px',
    marginRight: '0.4em'
  },
  arrowIcon: {
    paddingRight: "0.2em",
  },
  textField: {
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
    "& .MuiInputLabel-outlined": {
      color: theme.palette.secondary.dark
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.secondary.dark,
    },
  }
});

function CryptoSelect(props) {
  const theme = useTheme();
  const cryptoListLoaded = useSelector(state => state.cryptoListLoaded);
  const history = useHistory();
  const selectedCryptoCurrency = useSelector(state => state.selectedCryptoCurrency);
  const selectedCryptoCurrencyToSell = useSelector(state => state.selectedCryptoCurrencyToSell);
  const priceDetails = useSelector(state => state.priceDetails);
  const cryptoCurrencyList = useSelector(state => state.cryptoCurrencyList);
  const walletAddressMap = useSelector(state => state.walletAddressMap);
  const cryptoAmountToSell = useSelector(state => state.cryptoAmountToSell);
  const dispatch = useDispatch();
  const preferredToCurrency = props.toCurrency;
  const preferredFromCurrencyForSell = props.fromCurrencyForSell;
  const disableCryptoChange = props.disableCryptoChange === "true";
  const payingCrypto = props.payingCrypto;

  let supportedCryptoCurrenciesList = props.supportedCryptoCurrenciesList;
  if (supportedCryptoCurrenciesList && typeof supportedCryptoCurrenciesList === `string`) {
    supportedCryptoCurrenciesList = supportedCryptoCurrenciesList.split(',');
    supportedCryptoCurrenciesList = _.uniq(supportedCryptoCurrenciesList);
  }

  useEffect(() => {
    // isSample key determines that this is coming from a default state from redux and not from backend
    if (cryptoCurrencyList && cryptoCurrencyList.length && cryptoCurrencyList[0] && !cryptoCurrencyList[0].isSample) {
      return;
    }
    CurrencyService.getCryptoCurrencyList()
      .then((data) => {
        if (data && data.status === `success` && data.response && data.response.length) {
          let cryptoCurrencies = data.response;

          // Override list of crypto currencies based on url param
          if (supportedCryptoCurrenciesList && supportedCryptoCurrenciesList.length) {
            let filteredCurrencies = [];
            supportedCryptoCurrenciesList.forEach((supportedCurrency) => {
              const tempArray = _.filter(cryptoCurrencies, (currency) => {
                return currency.code.toLowerCase() === supportedCurrency.toLowerCase();
              });
              if (tempArray && tempArray.length) {
                filteredCurrencies = filteredCurrencies.concat(tempArray);
              }
            });
            if (filteredCurrencies.length) {
              cryptoCurrencies = filteredCurrencies;
            }
          }

          let defaultCryptoCurrency = cryptoCurrencies[0];

          if (preferredToCurrency) {
            const preferredToCurrencyList = _.filter(cryptoCurrencies, (currency) => {
              return currency.code.toLowerCase() === preferredToCurrency.toLowerCase();
            });
            if (preferredToCurrencyList && preferredToCurrencyList.length) {
              defaultCryptoCurrency = preferredToCurrencyList[0];
            }
          }

          let defaultCryptoCurrencyToSell;

          if (preferredFromCurrencyForSell) {
            defaultCryptoCurrencyToSell = lodash.find(cryptoCurrencies, (currency) => {
              return currency.sell_allowed === true && currency.code.toLowerCase() === preferredFromCurrencyForSell.toLowerCase();
            });  
          } else {
            defaultCryptoCurrencyToSell = lodash.find(cryptoCurrencies, (currency) => {
              return currency.sell_allowed === true;
            });
          }

          if (walletAddressMap && walletAddressMap[defaultCryptoCurrency.code.toLowerCase()]) {
            dispatch(setWalletAddress(walletAddressMap[defaultCryptoCurrency.code.toLowerCase()]));
          }

          dispatch(setCryptoListLoaded(true));
          dispatch(setCryptoCurrencies(cryptoCurrencies));
          dispatch(setSelectedCryptoCurrency(defaultCryptoCurrency));
          dispatch(setSelectedCryptoCurrencyToSell(defaultCryptoCurrencyToSell));
          dispatch(setCryptoAmountToSell(defaultCryptoCurrencyToSell.default_amount || 1));
        }
      })
      .catch((err) => {
        console.log(lodash.get(err, 'response.data.error', 'Something went wrong. Please try again later.'))
      })
  }, [dispatch, cryptoCurrencyList, props, preferredToCurrency, supportedCryptoCurrenciesList, walletAddressMap, preferredFromCurrencyForSell]);

  const handleClick = () => {
    history.push('/cryptoselectionform', { payingCrypto })
  }

  const buttonStyle = {
    height: "100%",
    paddingLeft: "2em",
    paddingRight: "1em",
    color: theme.palette.secondary.dark
  }
  
  const labelStyle = {
    fontSize: "18px",
    color: theme.palette.secondary.dark
  };
  
  const buttonTextStyle = {
    fontSize: "16px",
    marginRight: "0.2em",
    color: theme.palette.secondary.main
  };

  const handleAmountChange = (value) => {
    dispatch(setCryptoAmountToSell(value));
    dispatch(setPriceParamChanged('CRYPTO_AMOUNT'));
  };

  return (
    <Box style={{visibility: props.visibility || "visible"}}>
      {!cryptoListLoaded ? null :
        <Grid container className={props.classes.mainDiv}>
          <Grid item xs={12}>
            <FormControl style={formControlStyle}>
              <FormLabel style={labelStyle}>
                <Grid container>
                  <Grid item>
                      {payingCrypto ? "You Send" : "You Get"}
                  </Grid>
                  <Grid item>
                    {
                      payingCrypto ? null :
                        <CustomTooltip title={"The final amount of crypto transferred may change due to blockchain transaction fee and price volatility in the network"}>
                          <IconButton size="small">
                            <InfoRoundedIcon size="small" style={{ fontSize: "16px", color: theme.palette.secondary.dark }} />
                          </IconButton>
                        </CustomTooltip>
                    }
                  </Grid>
                </Grid>
              </FormLabel>
              <TextField
                value={payingCrypto ? (cryptoAmountToSell || '') : (priceDetails.cryptoCurrencyAmount || 'Calculating...')}
                onChange={(e) => handleAmountChange(e.target.value)}
                className={props.classes.textField}
                variant="outlined"
                disabled={!payingCrypto}
                margin="dense"
                inputProps={{ style: { height: "40px", fontSize: "20px", color: payingCrypto ? theme.palette.secondary.main : theme.palette.secondary.dark, fontFamily: "whitneymedium,muli,system-ui,Helvetica,sans-serif" } }}
                InputProps={{
                  style: { paddingRight: "0", borderRadius: "7px" },
                  endAdornment:
                    <Button style={buttonStyle} color="default" onClick={handleClick} disabled={disableCryptoChange}>
                      <Avatar className={props.classes.avatar} alt="crypto" src={payingCrypto ? selectedCryptoCurrencyToSell.image : selectedCryptoCurrency.image} />
                      <span style={buttonTextStyle}>{payingCrypto ? selectedCryptoCurrencyToSell.code : selectedCryptoCurrency.code}</span>
                      {!disableCryptoChange ? <ArrowDropDownIcon className={props.classes.arrowIcon} /> : null}
                    </Button>
                }} />
            </FormControl>
          </Grid>
        </Grid>
      }
    </Box>
  )
}

const formControlStyle = {
  width: '100%',
  height: '100%'
}

export default withStyles(styles)(CryptoSelect);
