import { Box, Grid, FormLabel } from '@material-ui/core';
import Alert from '@mui/material/Alert';
import { withStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import CustomFormButton from 'widgets/CustomFormButton';
import PaymentService from '../../lib/services/PaymentService';
import { useSelector } from 'react-redux';
import FormHeader from 'widgets/FormHeader';
import CustomFormTextField from 'widgets/CustomFormTextField';
import CustomLinearProgressBar from 'widgets/CustomLinearProgressBar';
import lodash from 'lodash';

const styles = theme => ({
  mainGrid: {
    padding: "1.5em",
    paddingTop: '1em',
    width: '100%'
  },
  errorText: {
    fontSize: "12px",
    marginTop: "10px",
    marginBottom: "10px",
    color: "#F5222D",
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
    display: "flex",
    justifyContent: "center",
    height: '10px'
  },
});

function AuthorizePayment(props) {
  const [smsCode, setSmsCode] = useState("");
  const [card2FACode, setCard2FACode] = useState("");
  const [invalidSmsCode, setInvalidSmsCode] = useState(false);
  const [invalidCard2FACode, setInvalidCard2FACode] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [httpCallInProgress, setHttpCallInProgress] = useState(false);
  const orderId = useSelector(state => state.orderId);

  const smsNeeded = lodash.get(props, 'location.state.smsNeeded');
  const card2faNeeded = lodash.get(props, 'location.state.card2faNeeded');

  if (!smsNeeded && !card2faNeeded) {
    props.history.push('/checkpayment');
    return (
      <div></div>
    );
  }

  const handleSmsCodeChange = (event) => {
    setSmsCode(event.target.value);
  };

  const handleCard2FACodeChange = (event) => {
    setCard2FACode(event.target.value);
  };

  const getValidationError = (params) => {
    let validForm = true;
    if (!smsCode && smsNeeded) {
      setInvalidSmsCode(true);
      validForm = false;
    } else {
      setInvalidSmsCode(false);
    }
    if (!card2FACode && card2faNeeded) {
      setInvalidCard2FACode(true);
      validForm = false;
    } else {
      setInvalidCard2FACode(false);
    }

    return validForm;
  }

  const handleSubmit = (event) => {
    setErrorText("");
    const body = {
      order_id: String(orderId),
      sms_code: smsCode,
      card2fa_code: card2FACode
    };
    const isValidForm = getValidationError(body);
    if (!isValidForm) {
      return;
    }

    setHttpCallInProgress(true);

    PaymentService.authorizePayment(body)
      .then((data) => {
        setHttpCallInProgress(false);
        if (data) {
          setErrorText("Authorization codes submitted");
          props.history.push('/checkpayment');
        } else {
          setErrorText("Something went wrong. Please try again later.");
        }
      })
      .catch((err) => {
        setHttpCallInProgress(false);
        setErrorText("Something went wrong. Please try again later.");
      })
  }

  const handleBackButton = () => {
    props.history.replace("/");
  }

  const emptyContainerHeight = smsNeeded && card2faNeeded ? "0px" : (smsNeeded ? "275px" : "90px");

  return (
    <Box className={props.classes.mainGrid}>
      <Grid container direction="column">
        <FormHeader titleText="Authorize Payment" history={props.history} customHandleBackButton={handleBackButton} />
      </Grid>
      <Grid container style={{ height: "40px" }}></Grid>

      {card2faNeeded ?
        <Grid container spacing={2} style={{ marginBottom: '10px' }}>
          <Grid item xs={12} sm={12}>
            <Alert severity="info">Our card processor will authorize your card with a micro-charge which includes one time, 6 digit passcode in the format "Wyre-123456". Please log in to your bank to retrieve the code and enter it here.</Alert>
          </Grid>
        </Grid>
        : null}

      {card2faNeeded ?
        <Grid container spacing={2} style={{ marginBottom: '30px' }}>
          <Grid item xs={12} sm={12}>
            <CustomFormTextField
              value={card2FACode}
              error={invalidCard2FACode}
              onChange={handleCard2FACodeChange}
              labelText="Card 2 FA Code"
            />
          </Grid>
        </Grid>
        : null}

      {smsNeeded ?
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <CustomFormTextField
              value={smsCode}
              error={invalidSmsCode}
              onChange={handleSmsCodeChange}
              labelText="SMS Code"
            />
          </Grid>
        </Grid>
        : null}

      <Grid container style={{ height: emptyContainerHeight }}></Grid>
      <FormLabel className={props.classes.errorText}>{errorText ? errorText : ''}</FormLabel>
      <Box>
        {httpCallInProgress ? <CustomLinearProgressBar /> : null}
        <CustomFormButton onClick={handleSubmit} disabled={httpCallInProgress} buttonText="Submit" />
      </Box>
    </Box>
  )
}

export default withStyles(styles)(AuthorizePayment);
