const cryptoAmountToSellReducer = (state = 0, action) => {
  switch (action.type) {
      case 'SET_CRYPTO_AMOUNT_TO_SELL':
          return action.payload;

      default:
          return state;
  }
}


export default cryptoAmountToSellReducer;
