const firstTimePageLoaded = (state = false, action) => {
    switch (action.type) {
        case 'SET_FIRST_TIME_PAGE_LOADED':
            return action.payload;
  
        default:
            return state;
    }
  }
  
  
  export default firstTimePageLoaded;
  