const selectedPaymentMethodReducer = (state = {id: 1, name: 'UPI', image: 'https://cdn.icon-icons.com/icons2/2699/PNG/512/upi_logo_icon_170312.png'}, action) => {
  switch (action.type) {
      case 'SET_SELECTED_PAYMENT_METHOD':
          return action.payload;

      default:
          return state;
  }
}

export default selectedPaymentMethodReducer;
