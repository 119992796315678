import React from 'react';
import Lottie from 'react-lottie';


function CustomLottie(props) {

  const lottieOptions = {
    loop: props.loop || false,
    autoplay: true,
    animationData: props.lottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };


  return (
    <Lottie
      speed={props.speed}
      options={lottieOptions}
      isClickToPauseDisabled={true}
      width={props.width}
      height={props.height}
    />
  );
}

export default CustomLottie;
