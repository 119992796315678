import ApiConstant from '../ApiConstant';
import Request from '../Request';

class PaymentService {
  
  static createPayment(body, headers) {
		let opts = {
		  method: ApiConstant.createPayment.method,
      url: ApiConstant.createPayment.url,
      data: body
		}

    if (headers) {
      opts.headers = headers;
    }

		return Request.Fire(opts);
	}

  static addBankAccount(body) {
    let opts = {
		  method: ApiConstant.addBankAccount.method,
      url: ApiConstant.addBankAccount.url,
      data: body
		}

		return Request.Fire(opts);
  }

  static generateCryptoWalletForSell(body) {
    let opts = {
		  method: ApiConstant.generateCryptoWalletForSell.method,
      url: ApiConstant.generateCryptoWalletForSell.url,
      data: body
		}

		return Request.Fire(opts);
  }

  static authorizePayment(body) {
		let opts = {
		  method: ApiConstant.authorizePayment.method,
      url: ApiConstant.authorizePayment.url,
      data: body
		}

		return Request.Fire(opts);
	}

}

export default PaymentService;
