import React from 'react'
import { Paper, Grid } from '@material-ui/core'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Footer from 'widgets/Footer';
import { useSelector } from 'react-redux';

const getMuiTheme = (primaryColor, secondaryColorMain, secondaryColorDark) => {
  return createMuiTheme({
    palette: {
      primary: {
        main: primaryColor
      },
      secondary: {
        main: secondaryColorMain,
        light: '#f7f7f7',
        dark: secondaryColorDark
      },
      default: {
        main: '#1D0458'
      }
    },
  });
};

const CustomLayout = ({ children }) => {
	const isSmallDevice = useMediaQuery('(max-width:480px)');
  const isDarkTheme = useSelector(state => state.isDarkTheme) || false;
  const primaryColor = useSelector(state => state.primaryColor) || 
    (isDarkTheme ? "#ffffff" : "#1D0458");
  const secondaryColorMain = isDarkTheme ? "#ffffff" : "#000000";
  const secondaryColorDark = isDarkTheme ? "#ffffff" : "#727070";

  // const backgroundColor = isDarkTheme ? "#000000" : "rgb(243 235 246 / 20%)";
  const backgroundColor = isDarkTheme ? "#000000" : "#fff";

  const smallDevicePaperStyle = {
    display: 'flex',
    minHeight: '36em',
    height: "100%",
    width: '100%',
    border: "0.5px solid #d9dedb",
    borderRadius: "7px",
    backgroundColor,
  }
  
  const largeDevicePaperStyle = {
    display: "flex",
    minHeight: '36em',
    height: "36em",
    width: '400px',
    justifyContent: 'center',
    margin: 'auto',
    borderRadius: "7px",
    backgroundColor,
  }

	return (
		<MuiThemeProvider theme={getMuiTheme(primaryColor, secondaryColorMain, secondaryColorDark)}>
			<div style={(!isSmallDevice ? {paddingTop: '5%', paddingBottom: '5%', width: "100%", "height": "100vh"} : { width: "100%", "padding": "0.3em"})}>
				<Paper elevation={isSmallDevice ? 0 : 1} style={isSmallDevice ? smallDevicePaperStyle : largeDevicePaperStyle }>
					<Grid container direction="column">
						<Grid item style={{height: "95%"}}>
							{children}
						</Grid>
						<Grid item style={{height: "5%"}}>
							<Footer />
						</Grid>
					</Grid>
				</Paper>
			</div>
		</MuiThemeProvider>
	)
}

export default CustomLayout
