import React, { useState } from 'react'
import { Tabs, Tab, Grid, Box, IconButton } from '@material-ui/core';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import MenuIcon from '@material-ui/icons/Menu';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";

import { useTheme } from '@material-ui/core/styles';
import { setTransactionType } from 'actions';

const useStyles = makeStyles((theme) => ({
  tabContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tab: {
    width: '100%',
    display: 'flex',
    "& .MuiTab-wrapper": {
      flexDirection: "row-reverse",
      justifyContent: "center"
    },
    "& .MuiTab-labelIcon": {
      minHeight: "50px"
    }
  },
  tabItem: {
    flex: 1,
  },
  menuDiv: {
    display: "flex",
    justifyContent: "flex-end"
  },
}));

function MainPageHeader(props) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const classes = useStyles();
  const selectedCryptoCurrency = useSelector(state => state.selectedCryptoCurrency);
  const mode = props.mode;
  const buyLabel = props.mode === "buy" ? `Buy ${selectedCryptoCurrency.name}` : "Buy";
  const sellLabel = props.mode === "sell" ? `Sell ${selectedCryptoCurrency.name}` : "Sell";
  const tabGridWidth = (props.mode === "buy" || props.mode === "sell") ? 8 : 10;
  const selectedTab = props.selectedTab;

  const handleMenuButton = () => {
    props.history.push('/menupage');
  }

  const [value, setValue] = useState(selectedTab);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === "sell") {
      dispatch(setTransactionType("SELL"));
      navigateToSell();
    } else {
      dispatch(setTransactionType("BUY"));
      navigateToBuy();
    }
  };

  const navigateToBuy = () => {
    props.history.push("/");
  }

  const navigateToSell = () => {
    props.history.push("/sell");
  }

  return (
    <Box>
      <Grid container>
        { (mode === "buy" || mode === "sell") ? <Grid item xs={2} className={classes.menuDiv}> </Grid> : null}
        <Grid item xs={tabGridWidth} className={classes.tabContainer}>
          <Tabs value={value} onChange={handleChange} className={classes.tab} indicatorColor="primary">
            { mode === "sell" ? null : <Tab value={"buy"} label={buyLabel} key={0} className={classes.tabItem} style={{color: theme.palette.secondary.main}} /> }
            { mode === "buy" ? null : <Tab value={"sell"} label={sellLabel} key={1} icon={<FiberNewIcon style={{color: "#000", fontSize: "20px", marginLeft: "5px"}} />} className={classes.tabItem} style={{color: theme.palette.secondary.main}} />}
          </Tabs>
        </Grid>
        <Grid item xs={2} className={classes.menuDiv}>
          <IconButton edge="end" color="inherit" aria-label="menu" onClick={handleMenuButton}>
            <MenuIcon style={{ fontSize: 27, color: theme.palette.secondary.main }} />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  )

}

export default MainPageHeader;
