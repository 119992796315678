
const userAddressReducer = (state = { addressLine1: "", addressLine2: "", state: "", country: "", city: "", postalCode: "" }
  , action) => {
  switch (action.type) {
    case 'SET_USER_ADDRESS':
      return action.payload;

    default:
      return state;
  }
}


export default userAddressReducer;
