import { Box, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { useState } from 'react';
import lodash from 'lodash';
import { useSelector } from 'react-redux';
import ApiConstant from 'lib/ApiConstant';
import OrderService from 'lib/services/OrderService';
import paymentCheckAnimation from 'assets/animations/payment_check.json';
import paymentSuccessAnimation from 'assets/animations/success.json';
import paymentRejectedAnimation from 'assets/animations/failed.json';
import CustomLottie from 'widgets/CustomLottie';
import CustomFormButton from 'widgets/CustomFormButton';

const styles = theme => ({
  header: {
    display: 'flex',
    height: '70px',
    backgroundColor: '#fbfcf8',
    marginLeft: '-1.5em',
    marginRight: '-1.5em',
    marginTop: '-1em',
  },
  text: {
    fontSize: 13
  },
  mainGrid: {
    display: "flex",
    flexDirection: 'column',
    justifyContent: "center",
    alignItems: "center",
    padding: "2em",
    marginTop: "35%",
  },
  mainText: {
    display: "flex",
    justifyContent: "center",
    alignItems: 'center',
    textAlign: "center",
    color: theme.palette.secondary.main
  },
  lottie: {
    display: "flex",
    justifyContent: "center",
    alignItems: 'center',
    textAlign: "center",
  },
  actionButton: {
    marginTop: "20px"
  }
});

function CheckPayment(props) {
  const [mainText, setMainText] = useState("We are checking your payment status. Please stay on this screen after attempting payment.");
  const [paymentStatus, setPaymentStatus] = useState("PENDING");
  const authToken = lodash.get(props, 'location.state.authToken');
  const successText = lodash.get(props, 'location.state.successText');
  const failureText = lodash.get(props, 'location.state.failureText');
  const partnerAPIKeyFilter = lodash.get(props, 'location.state.partnerAPIKeyFilter');
  const isCheckoutFlow = lodash.get(props, 'location.state.isCheckoutFlow');
  const authWindow = lodash.get(props, 'location.state.authWindow');
  const orderId = useSelector(state => state.orderId);

  let pollingCount = 0;
  let intervalId = setInterval(function checkStatusIfTabInFocus() {
    if (document.visibilityState === `visible` || isCheckoutFlow) {
      if (pollingCount >= 10) {
        setMainText("It is taking more that usual to verify your payment status. Please bear with us.");
      }
      let headers;
      if (authToken) {
        headers = {"x-rl-auth-token": authToken};
      }
      let qs;
      if (partnerAPIKeyFilter) {
        qs = {apiKey: partnerAPIKeyFilter}
      }
      OrderService.getOrderById(orderId, qs, headers)
        .then((response) => {
          const orderStatus = lodash.get(response, 'status');
          if ([`COMPLETED`, `PROCESSING_CRYPTO`, `RELEASING_CRYPTO`].indexOf(orderStatus) > -1) {
            setMainText(successText || "Payment received successfully. You can check the status of crypto transfer in the My Orders section.");
            setPaymentStatus('SUCCESS');
            authWindow && authWindow.close();
            clearInterval(intervalId);
          } else if (orderStatus === `FAILED`) {
            setMainText(failureText || "Your payment could not be completed. Check My Orders section for more details.");
            setPaymentStatus('FAILED');
            authWindow && authWindow.close();
            clearInterval(intervalId);
          }
          pollingCount++;
        })
        .catch((e) => {

        });
    }
  }, ApiConstant.getOrder.pollingIntervalInMs);

  function getPaymentLottie() {
    if (paymentStatus === `SUCCESS`) {
      return (<CustomLottie
        lottie={paymentSuccessAnimation}
        loop={false}
        speed={2.0}
        width={100}
        height={100}
      />);
    } else if (paymentStatus === `FAILED`) {
      return (<CustomLottie
        lottie={paymentRejectedAnimation}
        loop={false}
        width={100}
        hieght={100}
      />);
    } else {
      return (<CustomLottie
        lottie={paymentCheckAnimation}
        loop={true}
        width={100}
        hieght={100}
      />);
    }
  }

  const handleOrderListClick = () => {
    props.history.replace("/orderlist",  { callingPage: "CHECK_PAYMENT" });
  };

  function getPaymentStatusAction() {
    if ((paymentStatus === `SUCCESS` || paymentStatus === `FAILED`) && !isCheckoutFlow) {
      return (<CustomFormButton onClick={handleOrderListClick} buttonText="My Orders" />);
    } else {
      return (<div></div>);
    }
  }

  return (
    <Box style={mainDivStyle}>
      <Grid container direction="column" className={props.classes.mainGrid}>
        <Grid item className={props.classes.lottie}>
          {getPaymentLottie()}
        </Grid>
        <Grid item>
          <span className={props.classes.mainText}>{mainText}</span>
        </Grid>
        <Grid item className={props.classes.actionButton}>
          {getPaymentStatusAction()}
        </Grid>
      </Grid>
    </Box>
  )
}

const mainDivStyle = {
  padding: '1.5em',
  paddingTop: '1em',
}

export default withStyles(styles)(CheckPayment);
