const GET = "GET";
const POST = "POST";
const PUT = "PUT";
const PATCH = "PATCH";

class ApiConstant {

  static get fiatCurrencyList() {
    return {
      url: '/v1/fiat_currencies',
      method: GET
    };
  }

  static get cryptoCurrencyList() {
    return {
      url: '/v1/crypto_currencies',
      method: GET
    };
  }

  static get pricingDetails() {
    return {
      url: '/v1/pricing',
      method: POST
    };
  }

  static get requestVerificationCode() {
    return {
      url: '/v1/user/register',
      method: POST
    };
  }

  static get validateVerificationCode() {
    return {
      url: '/v1/user/login',
      method: POST
    };
  }

  static get decodeQueryParams() {
    return {
      url: '/v1/decode/params',
      method: POST
    };
  }

  static get submitUserProfile() {
    return {
      url: '/v1/user',
      method: PUT
    };
  }
  
  static get submitUserAddress() {
    return {
      url: '/v1/address',
      method: POST
    };
  }
  
  static get logout() {
    return {
      url: '/v1/user/logout',
      method: POST
    };
  }

  static get getUserProfile() {
    return {
      url: '/v1/user',
      method: GET
    };
  }

  static get getPhoneIsdCodes() {
    return {
      url: '/v1/phone/codes',
      method: GET
    };
  }
  
  static get getTransactionLimits() {
    return {
      url: '/v1/user/transaction/limits',
      method: GET
    };
  }

  static get getKycOnboardingUrl() {
    return {
      url: `/v1/user/onboard/higherlimit`,
      method: GET
    }
  }

  static get generateSdKToken() {
    return {
      url: '/v1//kyc/sdk_token',
      method: POST
    };
  }

  static get submitKycDocuments() {
    return {
      url: '/v1/kyc/submit',
      method: POST
    };
  }

  static get getKycStatus() {
    return {
      url: '/v1/kyc/check',
      method: GET,
      pollingIntervalInMs: 3000
    };
  }
  
  static get checkHigherLimitStatus() {
    return {
      url: '/v1/user/higher_limit/kyc',
      method: GET,
      pollingIntervalInMs: 3000
    };
  }

	static get orderCreate() {
    return {
      url: '/v1/order',
      method: POST
    };
  }

  static get orderTransactionCreate() {
    return {
      url: '/v1/order/transaction',
      method: POST
    }
  }
  
  static get getOrder() {
    return {
      url: '/v1/order',
      method: GET,
      pollingIntervalInMs: 2000
    };
  }

  static get getPartnerOrder() {
    return {
      url: '/v1/partner/order/detail',
      method: GET,
    };
  }

  static get updatePartnerOrder() {
    return {
      url: '/v1/partner/order',
      method: PATCH,
    };
  }
  
  static get requestOrderVerification() {
    return {
      url: '/v1/partner/order/verify/request',
      method: POST,
    };
  }

  static get verifyOrder() {
    return {
      url: '/v1/partner/order/verify',
      method: POST,
    };
  }
  
  static get createPayment() {
    return {
      url: '/v1/fiat-payment',
      method: POST
    };
  }
  
  static get authorizePayment() {
    return {
      url: '/v1/fiat-payment/authorize',
      method: POST
    };
  }

  static get addBankAccount() {
    return {
      url: '/v1/fiat-payment/add',
      method: POST
    };
  }
  
  static get generateCryptoWalletForSell() {
    return {
      url: '/v1/fiat-payment/cryptowallet',
      method: POST
    };
  }

}

export default ApiConstant
