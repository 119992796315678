import { Box, OutlinedInput, InputAdornment } from '@material-ui/core';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { useSelector, useDispatch } from 'react-redux';
import { setEmailAddress } from '../../../actions';
import { useTheme } from '@material-ui/core/styles';

function EmailField(props) {
  const theme = useTheme();
  const emailAddress = useSelector(state => state.emailAddress);
  const dispatch = useDispatch();

  const handleChange = (event) => {
    dispatch(setEmailAddress(event.target.value));
  };

  return (
    <Box>
      <OutlinedInput
        id="outlined-adornment-weight"
        value={emailAddress}
        onChange={handleChange}
        startAdornment={
          <InputAdornment position="start">
            <MailOutlineIcon />
          </InputAdornment>
        }
        style={{
          width: "100%",
          fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
          backgroundColor: theme.palette.secondary.light
        }}
        placeholder="johndoe@example.com"
        aria-describedby="outlined-weight-helper-text"
        inputProps={{
          'aria-label': 'weight',
        }}
        labelWidth={0}
      />
    </Box>
  )
}


export default EmailField;
