import { Box, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import SelectSearch from 'react-select-search';
import { useSelector, useDispatch } from 'react-redux';
import { setPriceParamChanged, setSelectedFiatCurrency, setSelectedFiatCurrencyForSell } from '../../actions';
import lodash from 'lodash';
import Fade from '@mui/material/Fade';

import fuzzySearch from './fuzzySearch';
import FormHeader from 'widgets/FormHeader';

const styles = theme => ({
	mainGrid: {
		padding: "1.5em",
		paddingTop: '1em'
	},
});

function FiatSelectionForm(props) {
	const fiatCurrencyList = useSelector(state => state.fiatCurrencyList);
  const filteredList = [];
  const payingCrypto = lodash.get(props, 'location.state.payingCrypto');
	fiatCurrencyList.forEach((currency) => {
		currency.value = currency.fiat_code;
		currency.name = currency.fiat_name;
    if (payingCrypto) {
      if (currency.sell_allowed === true) {
        filteredList.push(currency);
      }
    } else {
      filteredList.push(currency);
    }
	});

	const dispatch = useDispatch();

	function renderValue(props, option, snapshot, className) {
		const imgStyle = {
			verticalAlign: 'middle',
			marginRight: 10,
			marginTop: '5px'
		};

		return (
			<button {...props} className={className} type="button" key={`${option.fiat_code}_${option.country_id}`}>
				<Grid container>
					<Grid item xs={2}>
						<img alt="" style={imgStyle} width="35" height="25" src={option.country_flag_url} />
					</Grid>
					<Grid item xs={8}>
						<Typography style={textStyle}>{option.fiat_name}</Typography>
					</Grid>
					<Grid item xs={2}>
						<Typography style={textStyle}>{option.fiat_code}</Typography>
					</Grid>
				</Grid>
			</button>
		);
	}

	const handleClick = (value) => {
		const selectedFiatCurrency = lodash.find(filteredList, function (currency) { return currency.value === value; });
    if (payingCrypto) {
      dispatch(setSelectedFiatCurrencyForSell(selectedFiatCurrency));
      dispatch(setPriceParamChanged('FIAT_SELECT'));
    } else {
      dispatch(setSelectedFiatCurrency(selectedFiatCurrency));
    }
		props.history.goBack();
	}

	return (
		<Fade in={true}>
			<Box overflow="auto" className={props.classes.mainGrid}>
				<Grid container direction="column">
					<Grid item>
						<FormHeader titleText="Select Fiat Currency" history={props.history} />
					</Grid>
				</Grid>
				<Grid container style={formControlStyle}>
					<Grid item xs={12}>
						<SelectSearch
							className="select-search select-search--multiple"
							options={filteredList}
							renderOption={renderValue}
							search
							filterOptions={fuzzySearch}
							placeholder="Search"
							printOptions="always"
							onChange={handleClick}
						/>
					</Grid>
				</Grid>
			</Box>
		</Fade>
	)
}

const formControlStyle = {
	width: '100%',
	height: '100%',
	// padding: '1.5em',
	// paddingTop: '0.5em',
	display: 'flex'
}

const textStyle = {
	fontSize: 15,
	display: 'flex',
	marginTop: '5px'
};

export default withStyles(styles)(FiatSelectionForm);
