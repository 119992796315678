import React from 'react';
import FormHeader from '../../widgets/FormHeader';
import VerifyEmailField from '../../widgets/VerifyEmailField';
import { Typography, Grid, Box, FormControlLabel, FormLabel, Link } from '@material-ui/core';
import CustomFormButton from '../../widgets/CustomFormButton';
import { withStyles } from '@material-ui/styles';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import AuthService from '../../lib/services/AuthService';
import util from '../../lib/util';
import CustomCheckBox from 'widgets/CustomCheckBox';
import CustomLinearProgressBar from 'widgets/CustomLinearProgressBar';
import UserService from '../../lib/services/UserService';
import { setUserAddress, setUserLoggedIn, setUserProfile } from 'actions';
import lodash from 'lodash';
import { setToken } from 'lib/Request';
import Fade from '@mui/material/Fade';

const styles = theme => ({
  mainDiv: {
    padding: '1.5em',
    paddingTop: '1em',
    width: '100%'
  },
  subText: {
    fontSize: "10px",
    marginTop: "10px",
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif"
  },
  errorText: {
    fontSize: "12px",
    marginTop: "10px",
    color: "#F5222D",
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif"
  },
  CustomFormButton: {
    marginTop: '16.5em'
  },
  errorTextDiv: {
    marginBottom: "0.5em",
    height: '3px'
  },
  checkBoxLabel: {
    fontSize: "12px",
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
    color: theme.palette.secondary.dark
  },
  linkText: {
    textDecoration: "underline"
  }
});

function VerifyEmailOTP(props) {
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);
  const [emailOTP, setEmailOTP] = useState("");
  const [httpCallInProgress, setHttpCallInProgress] = useState(false);
  const [errorText, setErrorText] = useState("");
  const emailAddress = useSelector(state => state.emailAddress);
  const transactionType = useSelector(state => state.transactionType);
  const callingPage = lodash.get(props, 'location.state.callingPage')

  const setOTP = (value) => {
    setEmailOTP(value);
  }

  const handleCheckBoxChange = (event) => {
    setChecked(event.target.checked);
  };

  const onClick = () => {
    setErrorText("");
    if (!util.isValidVerificationCode(emailOTP)) {
      setErrorText("* Please enter a valid six digit code");
      return;
    }
    setHttpCallInProgress(true);

    AuthService.validateVerificationCode({ email: emailAddress, otp: emailOTP })
      .then((loginResult) => {
        setHttpCallInProgress(false);
        if (loginResult && loginResult.status === `success` && loginResult.response && loginResult.response.authToken) {
          try {
            setToken(loginResult.response.authToken);
          } catch (e) {
            console.log(e);
          }
        }

        if (lodash.get(props, 'location.state.callingPage') === `MENU_PAGE`) {
          props.history.replace('/');
          return;
        }

        UserService.getUserProfile()
          .then((data) => {
            if (data && data.status === `success` && data.response) {
              dispatch(setUserLoggedIn(true));
              const user = data.response.user || {};
              const userData = {
                firstName: user.firstName || "",
                lastName: user.lastName || "",
                dateOfBirth: user.dateOfBirth || "",
                isdCode: user.isdCode || "",
                phoneNumber: user.phoneNumber || "",
              };
              dispatch(setUserProfile(userData));
              if (!(user.firstName && user.lastName && user.isdCode && user.phoneNumber && user.dateOfBirth)) {
                props.history.push('/userprofile');
                return;
              }

              if (user.address && Object.keys(user.address)) {
                dispatch(setUserAddress({
                  addressLine1: user.address.addressLine1,
                  addressLine2: user.address.addressLine2,
                  state: user.address.state,
                  country: user.address.country,
                  city: user.address.city,
                  postalCode: user.address.postalCode
                }));
              } else {
                props.history.push('/addressform');
                return;
              }
              if (user.kycStatus === `VERIFIED`) {
                if (transactionType === `SELL`) {
                  props.history.push('/connectbankaccount');
                } else {
                  props.history.push('/ordersummary');
                }
                return;
              } else {
                props.history.push('/kycform');
              }
            } else {
              props.history.push('/userprofile');
            }
          })
          .catch((err) => {
            console.log(lodash.get(err, 'response.data.error', 'Something went wrong. Please try again later.'))
            props.history.push('/userprofile');
          })

      })
      .catch((e) => {
        setHttpCallInProgress(false);
        setErrorText("Something went wrong. Please try again later.");
      });
  }

  return (
    <Fade in={true}>
      <Box className={props.classes.mainDiv}>
        <Grid container direction={'column'}>
          <Grid item>
            <FormHeader titleText="Verification Code" history={props.history} />
          </Grid>
          <Grid item style={{ height: "40px" }}></Grid>
          <Grid item>
            <VerifyEmailField setOTP={setOTP} />
          </Grid>
          <Grid item >
            <FormLabel className={props.classes.subText}>* We have sent a six digit code to {emailAddress}</FormLabel>
          </Grid>
          <Grid item className={props.classes.errorTextDiv}> {errorText ?
            <FormLabel className={props.classes.errorText}>{errorText}</FormLabel>
            : ''}
          </Grid>
          <Grid item>
          </Grid>
          <Grid item className={props.classes.CustomFormButton}>
            <FormControlLabel
              control={<CustomCheckBox checked={checked} onChange={handleCheckBoxChange} name="privacy" />}
              label={
                <Typography className={props.classes.checkBoxLabel}>
                  I agree to Rampay's&nbsp;
                  <Link href="https://rampay.io/termsofuse.html" target="_blank" rel="noreferrer" className={props.classes.linkText}>
                    Terms of Use
                  </Link> and&nbsp;
                  <Link href="https://rampay.io/privacy.html" target="_blank" rel="noreferrer" className={props.classes.linkText}>
                    Privacy Policy
                  </Link>
                </Typography>
              }
            />
            {httpCallInProgress ? <CustomLinearProgressBar /> : null}
            <CustomFormButton onClick={onClick} disabled={httpCallInProgress || !checked} buttonText="Submit Code" />
          </Grid>
        </Grid>
      </Box>
    </Fade>
  )

}

export default withStyles(styles)(VerifyEmailOTP);
