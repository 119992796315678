const walletAddressReducer = (state = '', action) => {
  switch (action.type) {
      case 'SET_WALLET_ADDRESS':
          return action.payload;

      default:
          return state;
  }
}

export default walletAddressReducer;
