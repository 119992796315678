import React from 'react'
import Typography from '@material-ui/core/Typography'
import { Grid, Box, IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { withStyles } from '@material-ui/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTheme } from '@material-ui/core/styles';

const styles = theme => ({
  mainGrid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  text: {
    fontFamily: 'whitneybook,muli,system-ui,Helvetica,sans-serif',
    fontSize: '20px',
    color: theme.palette.secondary.main
  },
  backButtonDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  menuButtonDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
  },
  wrappingGrid: {
  }
});

function FormHeader(props) {
  const theme = useTheme();

  const handleBackButton = () => {
    props.history.goBack();
  }

  const handleMenuButton = () => {
    props.history.push('/menupage');
  }

  return (
    <Box>
      <Grid container className={props.classes.wrappingGrid}>
        <Grid item xs={2} sm={2} className={props.classes.backButtonDiv}>
          {props.hideBackButton ? null : <IconButton onClick={props.customHandleBackButton || handleBackButton}>
            <ArrowBackIcon style={{color: theme.palette.secondary.main}} />
          </IconButton> }
        </Grid>
        <Grid item xs={8} sm={8} className={props.classes.mainGrid}>
          <Typography component="h4" className={props.classes.text}>{props.titleText}</Typography>
        </Grid>
        <Grid item xs={2} sm={2} className={props.classes.menuButtonDiv} style={{visibility: props.hideMenuButton ? "hidden" : "visible"}}>
          <IconButton edge="end" color="inherit" aria-label="menu" onClick={handleMenuButton}>
            <MenuIcon style={{color: theme.palette.secondary.main}} />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  )
}

export default withStyles(styles)(FormHeader);
