const emailAddressReducer = (state = '', action) => {
  switch (action.type) {
      case 'SET_EMAIL_ADDRESS':
          return action.payload;

      default:
          return state;
  }
}

export default emailAddressReducer;
