import React from 'react'
import { BrowserRouter as Router, Switch } from 'react-router-dom'
import LoginRoute from './router/LoginRoute'
import MainForm from './components/MainForm'
import './App.css'
import './selection_form_style.css'
class App extends React.Component {
  render() {
    return (
      <Router>
        <Switch>
          <LoginRoute exact path="/" component={MainForm} />
        </Switch>
      </Router>
    )
  }
}

export default App
