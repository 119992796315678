import PriceDetail from '../../widgets/PriceDetail'
import FormHeader from '../../widgets/FormHeader';
import WalletField from './widgets/WalletField';
import CustomFormButton from '../../widgets/CustomFormButton';
import { Grid, Box, Divider } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';
import util from '../../lib/util';
import { useSelector, useDispatch } from 'react-redux';
import lodash from 'lodash';
import UserService from '../../lib/services/UserService';
import { setUserAddress, setUserProfile } from 'actions';
import Fade from '@mui/material/Fade';
import { useEffect, useState, useCallback } from 'react';

const styles = theme => ({
  mainDiv: {
    padding: '1.5em',
    paddingTop: '1em',
    width: '100%'
  },
  text: {
    fontSize: '16px',
    fontFamily: 'whitneybook,muli,system-ui,Helvetica,sans-serif',
    color: theme.palette.secondary.main
  },
  textGrid: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  textLabelGrid: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  divider: {
    marginBottom: '20px'
  },
  priceDetailDiv: {
    height: '110px',
  },
});

function WalletForm(props) {
  const dispatch = useDispatch();
  const walletAddress = useSelector(state => state.walletAddress);
  const transactionType = useSelector(state => state.transactionType);
  const priceDetails = useSelector(state => state.priceDetails);
  const fiatAmount = useSelector(state => state.fiatAmount);
  const selectedCryptoCurrency = useSelector(state => state.selectedCryptoCurrency);
  const selectedFiatCurrency = useSelector(state => state.selectedFiatCurrency);
  const partnerOrderId = useSelector(state => state.partnerOrderId);
  const disableWalletAddressChange = props.disableWalletAddressChange;
  const [renderWalletForm, setRenderWalletForm] = useState(false);

  const fetchUserProfile = useCallback(() => {
    UserService.getUserProfile()
      .then((data) => {
        if (data && data.status === `success` && data.response) {
          const user = data.response.user || {};

          if (user.firstName && user.lastName && user.isdCode && user.phoneNumber) {
            // let dob = new Date(user.dob);
            // dob = `${dob.getMonth()+1}${dob.getDate()}${dob.getFullYear()}`;
            dispatch(setUserProfile({
              firstName: user.firstName,
              lastName: user.lastName,
              // dob,
              isdCode: user.isdCode,
              phoneNumber: user.phoneNumber,
            }));
          } else {
            props.history.push('/userprofile');
            return;
          }

          if (user.address && Object.keys(user.address)) {
            dispatch(setUserAddress({
              addressLine1: user.address.addressLine1,
              addressLine2: user.address.addressLine2,
              state: user.address.state,
              country: user.address.country,
              city: user.address.city,
              postalCode: user.address.postalCode
            }));
          } else {
            props.history.push('/addressform');
            return;
          }

          if (user.kycStatus === `VERIFIED`) {
            props.history.push('/ordersummary');
          } else {
            props.history.push('/kycform');
          }
        } else {
          props.history.push('/emailform');
        }
      })
      .catch((err) => {
        console.log(lodash.get(err, 'response.data.error', 'Something went wrong. Please try again later.'))
        props.history.push('/emailform');
      })
  }, [dispatch, props.history])

  useEffect(() => {
    if (partnerOrderId && disableWalletAddressChange === "true" && walletAddress) {
      fetchUserProfile();
    } else {
      setRenderWalletForm(true);
    }
  }, [partnerOrderId, disableWalletAddressChange, walletAddress, fetchUserProfile]);

  const onClick = () => {
    if (!util.isValidCryptoAddress(walletAddress, selectedCryptoCurrency.code)) {
      return;
    }
    fetchUserProfile();
  }

  const handleBackButton = () => {
    props.history.push("/");
  }

  return (
    renderWalletForm ? <Fade in={true}>
      <Box className={props.classes.mainDiv}>
        <Grid container direction={'column'} >
          <Grid item className={props.classes.header}>
            <FormHeader titleText={`Wallet Address`} history={props.history} customHandleBackButton={handleBackButton} />
          </Grid>
          <Grid item style={{ height: "40px" }}></Grid>
          <Grid item>
            <WalletField disableWalletAddressChange={disableWalletAddressChange} />
          </Grid>
          <Grid item style={{ height: "30px" }}></Grid>
          <Grid item >
            <Box style={{ display: 'flex', flexDirection: 'column', }}>
              <Grid container>
                <Grid item xs={6} className={props.classes.textLabelGrid}>
                  <Typography component="h4" className={props.classes.text}>You Pay:</Typography>
                </Grid>
                <Grid item xs={2}>

                </Grid>
                <Grid item xs={4} className={props.classes.textGrid}>
                  <Typography component="h4" className={props.classes.text}>{selectedFiatCurrency.fiat_symbol}{fiatAmount}</Typography>
                </Grid>
              </Grid>
              <Grid container style={{ marginTop: '1em' }}>
                <Grid item xs={3} className={props.classes.textLabelGrid}>
                  <Typography component="h4" className={props.classes.text}>You Get:</Typography>
                </Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={7} className={props.classes.textGrid}>
                  <Typography component="h4" className={props.classes.text}>{priceDetails.cryptoCurrencyAmount}
                    {selectedCryptoCurrency.code.toUpperCase()}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item className={props.classes.divider}>
            <Divider />
          </Grid>
          <Grid item className={props.classes.priceDetailDiv}>
            <PriceDetail />
          </Grid>
          <Grid item style={{ height: "35px" }}></Grid>
          <Grid item >
            <CustomFormButton onClick={onClick} buttonText="Continue" />
          </Grid>
        </Grid>
      </Box>
    </Fade> : <Box></Box>
  )
}

export default withStyles(styles)(WalletForm);
