import { Box, Grid, FormLabel, Select, MenuItem } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { useState } from 'react';
import CustomFormButton from 'widgets/CustomFormButton';
import UserService from '../../lib/services/UserService';
import FormHeader from 'widgets/FormHeader';
import CustomFormTextField from 'widgets/CustomFormTextField';
import CustomLinearProgressBar from 'widgets/CustomLinearProgressBar';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import lodash from 'lodash';
import { setUserProfile } from 'actions';
import countryCodes from 'country-codes-list';
import { useTheme } from '@material-ui/core/styles';
import Fade from '@mui/material/Fade';

const styles = theme => ({
  mainGrid: {
    padding: "1.5em",
    paddingTop: '1em',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    display: 'flex'
  },
  avatar: {
    width: '25px',
    height: '25px',
    marginRight: '0.6em',
  },
  menuItem: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  select: {
    "& .MuiSelect-select:focus": {
      backgroundColor: "white",
    },
  },
  fieldDiv: {
    marginBottom: '20px',
  },
  label: {
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
    color: theme.palette.secondary.dark
  },
  errorText: {
    fontSize: "12px",
    color: "#F5222D",
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
    height: '3px'
  },
  submitButton: {
    marginTop: '0.65em'
  }
});

function UserProfile(props) {
  const theme = useTheme();
  const userProfile = useSelector(state => state.userProfile);
  let isdCodeMap = countryCodes.customList('countryCode', '+{countryCallingCode}');
  let isdCodeList = Object.keys(isdCodeMap).map((key) => {
    return {
      countryCode: key,
      isdCode: isdCodeMap[key]
    }
  });
  isdCodeList = isdCodeList.sort((a, b) => {
    return String(a.isdCode) - String(b.isdCode);
  });
  isdCodeList = lodash.uniqBy(isdCodeList, function (e) {
    return e.isdCode;
  });

  const [isdCode, setIsdCode] = useState(userProfile.isdCode || "");
  const [phoneNumber, setPhoneNumber] = useState(userProfile.phoneNumber || "");
  const [firstName, setFirstName] = useState(userProfile.firstName || "");
  const [lastName, setLastName] = useState(userProfile.lastName || "");
  const [dateOfBirth, setDateOfBirth] = useState(userProfile.dateOfBirth || "");
  const [invalidFirstName, setInvalidFirstName] = useState(false)
  const [invalidLastName, setInvalidLastName] = useState(false)
  const [invalidDOB, setInvalidDOB] = useState(false)
  const [invalidPhoneNumber, setInvalidPhoneNumber] = useState(false)
  const [errorText, setErrorText] = useState("NO_ERROR");
  const [httpCallInProgress, setHttpCallInProgress] = useState(false);

  const dispatch = useDispatch();

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const handleDateOfBirthChange = (event) => {
    setDateOfBirth(event.target.value);
  };

  const handleIsdCodeChange = (event) => {
    setIsdCode(event.target.value);
  };

  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  const getValidationError = (params) => {
    let validForm = true;
    if (!firstName) {
      setInvalidFirstName(true);
      validForm = false;
    } else {
      setInvalidFirstName(false);
    }

    if (!lastName) {
      setInvalidLastName(true);
      validForm = false;
    } else {
      setInvalidLastName(false);
    }

    if (!dateOfBirth || !(moment(dateOfBirth, 'YYYY-MM-DD').isValid())) {
      setInvalidDOB(true);
      validForm = false;
    } else {
      setInvalidDOB(false);
    }

    if (!phoneNumber || !(/^[0-9]{5,20}$/.test(phoneNumber))) {
      setInvalidPhoneNumber(true);
      validForm = false;
    } else {
      setInvalidPhoneNumber(false);
    }

    return validForm;
  }

  const handleSubmit = (event) => {
    setErrorText("NO_ERROR");
    const body = {
      firstName, lastName, isdCode, phoneNumber, dateOfBirth,
    };
    const isValidForm = getValidationError(body);
    if (!isValidForm) {
      return;
    }
    setHttpCallInProgress(true);

    UserService.submitUserProfile(body)
      .then((data) => {
        setHttpCallInProgress(false);
        if (data && data.status === `success`) {
          setErrorText("User profile submitted successfully");
          dispatch(setUserProfile({
            firstName: body.firstName,
            lastName: body.lastName,
            dateOfBirth: dateOfBirth,
            isdCode: body.isdCode,
            phoneNumber: body.phoneNumber,
          }));
          if (lodash.get(props, 'location.state.callingPage') === `CONNECT_BANK_ACCOUNT`) {
            props.history.push('/connectbankaccount');
            return;
          } else if (lodash.get(props, 'location.state.callingPage') === `MENU_PAGE`) {
            props.history.goBack();
            return;
          }
          props.history.push('/addressform');
        } else {
          setErrorText("Something went wrong. Please try again later.");
        }
      })
      .catch((err) => {
        setHttpCallInProgress(false);
        setErrorText("Something went wrong. Please try again later.");
      })
  }

  return (
    <Fade in={true}>
      <Box overflow="auto" className={props.classes.mainGrid}>
        <Grid container direction="column">
          <Grid item style={{ height: "26em" }}>
            <Grid container direction="column">
              <Grid item>
                <FormHeader titleText="User Profile" history={props.history} />
              </Grid>
              <Grid item style={{ height: "40px" }}></Grid>
            </Grid>
            <Grid container className={props.classes.fieldDiv}>
              <Grid item xs={12} sm={12}>
                <CustomFormTextField
                  labelText="First Name*"
                  width="100%"
                  value={firstName}
                  error={invalidFirstName}
                  onChange={handleFirstNameChange}
                />
              </Grid>
            </Grid>
            <Grid container className={props.classes.fieldDiv}>
              <Grid item xs={12} sm={12}>
                <CustomFormTextField
                  labelText="Last Name*"
                  width="100%"
                  value={lastName}
                  error={invalidLastName}
                  onChange={handleLastNameChange}
                />
              </Grid>
            </Grid>
            <Grid container className={props.classes.fieldDiv}>
            <Grid item xs={12} sm={12}>
              <CustomFormTextField
                size="small"
                labelText="Date of Birth* (YYYY-MM-DD)"
                width="100%"
                value={dateOfBirth}
                error={invalidDOB}
                onChange={handleDateOfBirthChange}
                placeholder="1990-01-31"
              />
            </Grid>
          </Grid>
            <Grid container spacing={2}>
              <Grid item xs={5} sm={5}>
                <FormLabel className={props.classes.label}>Phone Number*</FormLabel>
                <Select
                  className={props.select}
                  value={isdCode}
                  onChange={handleIsdCodeChange}
                  height="50%"
                  color="secondary"
                  style={{
                    width: "100%",
                    height: "70%",
                    color: theme.palette.secondary.dark,
                    border: "1px solid",
                    borderColor: theme.palette.secondary.dark
                  }}
                  variant="outlined"
                >
                  {isdCodeList.map((item) => (
                    <MenuItem key={item.countryCode} value={item.isdCode}>
                      <Box className={props.classes.menuItem}>
                        <span className={props.classes.isdCodeSelect}> {item.isdCode} </span>
                      </Box>
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              {/**
         * TODO Sagar: This phone number UI seems distorted in mobile view. Fix it.
         */}
              <Grid item xs={7} sm={7} style={{ marginTop: "21px" }}>
                <CustomFormTextField
                  width="100%"
                  value={phoneNumber}
                  error={invalidPhoneNumber}
                  onChange={handlePhoneNumberChange}
                  placeholder=""
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item style={{ height: "2em" }}>

          </Grid>
          <Grid item style={{ height: "2em" }}>
            <FormLabel className={props.classes.errorText} style={{ visibility: errorText !== "NO_ERROR" ? "visible" : "hidden" }}>{errorText}</FormLabel>
            <Box className={props.classes.submitButton}>
              {httpCallInProgress ? <CustomLinearProgressBar /> : null}
              <CustomFormButton onClick={handleSubmit} disabled={httpCallInProgress} buttonText="Submit" />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Fade>
  )
}

export default withStyles(styles)(UserProfile);
