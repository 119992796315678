import { Box, OutlinedInput, InputAdornment } from '@material-ui/core';
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
import { withStyles } from '@material-ui/styles';
import { useState } from 'react';
import { useTheme } from '@material-ui/core/styles';

const styles = theme => ({
  mainGrid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap'
  }
});

function VerifyEmailField(props) {
  const theme = useTheme();
  const [emailOTP, setEmailOTP] = useState("");

  const handleChange = (event) => {
    setEmailOTP(event.target.value);
    props.setOTP(event.target.value);
  };
  const textFieldStyle = {
    width: "100%",
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
    backgroundColor: theme.palette.secondary.light,
    ...(props.customStyle || {})
  };

  return (
    <Box>
      <Box className={props.classes.mainGrid}>
        <OutlinedInput
          id="outlined-adornment-weight"
          value={emailOTP}
          onChange={handleChange}
          type="password"
          startAdornment={
            <InputAdornment position="start">
              <VerifiedUserOutlinedIcon />
            </InputAdornment>
          }
          style={textFieldStyle}
          placeholder={props.placeholderText || "Please enter verification code"}
          aria-describedby="outlined-weight-helper-text"
          inputProps={{
            'aria-label': 'weight',
          }}
          labelWidth={0}
        />

      </Box>
    </Box>
  )
}

export default withStyles(styles)(VerifyEmailField);
