const fiatListLoaded = (state = false, action) => {
  switch (action.type) {
      case 'SET_FIAT_LIST_LOADED':
          return action.payload;

      default:
          return state;
  }
}

export default fiatListLoaded;
