import { Box, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import SelectSearch from 'react-select-search';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedPaymentMethod } from '../../actions';
import lodash from 'lodash';


import fuzzySearch from './fuzzySearch';

const styles = theme => ({
});

function PaymentMethodSelectionForm(props) {
	const allowedFiatPaymentMethods = useSelector(state => state.allowedFiatPaymentMethods);
	allowedFiatPaymentMethods.forEach((paymentMethod) => {
		paymentMethod.value = paymentMethod.name
	});


	const dispatch = useDispatch();

	function renderValue(props, option, snapshot, className) {
		const imgStyle = {
			// borderRadius: '50%',
			verticalAlign: 'middle',
			marginRight: 10,
			marginTop: '5px'
		};

		return (
			<button {...props} className={className} type="button">
				<Grid container> <Grid item xs={2}> <img alt="" style={imgStyle} width="35" height="25" src={option.image} /> </Grid> <Grid item xs={8}> <Typography style={{ fontSize: 15, display: 'flex', marginTop: '5px' }}>{option.name}</Typography> </Grid> <Grid item xs={2}><Typography style={{ fontSize: 15, marginTop: '5px' }}>{option.name}</Typography></Grid></Grid>
			</button>
		);
	}

	const handleClick = (value) => {
		const selectedPaymentMethod = lodash.find(allowedFiatPaymentMethods, function (currency) { return currency.value === value; });
		dispatch(setSelectedPaymentMethod(selectedPaymentMethod))
		props.history.goBack();
	}


	return (
		<Box style={mainBoxStyle}>
			<Grid container style={formControlStyle}>
				<Grid item xs={12}>
					<SelectSearch
						className="select-search select-search--multiple"
						options={allowedFiatPaymentMethods}
						renderOption={renderValue}
						search
						filterOptions={fuzzySearch}
						placeholder="Search Payment Method"
						printOptions="always"
						onChange={handleClick}
					/>
				</Grid>
			</Grid>
		</Box>
	)
}

const formControlStyle = {
	width: '100%',
	height: '100%',
	padding: '1.5em',
	paddingTop: '0.5em'
}

const mainBoxStyle = {
	width: '100%'
}

export default withStyles(styles)(PaymentMethodSelectionForm);
