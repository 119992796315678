import Axios from 'axios';
import Config from '../config';
import lodash from 'lodash';
const validStatusCodes = [200]
const authenticationErrorStatusCodes = [401]
const RL_AUTH_HEADER = "x-rl-auth-token";

const AxiosClient = Axios.create({
  baseURL: Config.apiHost,
  responseType: 'json',
  withCredentials: true,
})

let currentAuthToken = null;

export function setToken(token) {
  currentAuthToken = token;
}

class Request {

  static Fire (config) {
    const requestPromise = new Promise((resolve, reject) => {

      // Adding a request interceptor to send auth token in headers in every request
      AxiosClient.interceptors.request.use(
        config => {
          try {
            const token = currentAuthToken;
            if (token) {
              config.headers[RL_AUTH_HEADER] = token;
            }
            return config;
          } catch(e) {
            console.log(e);
            return config;
          }
        },
        error => {
          console.log("errorr", error);
          // return Promise.reject(error);
        }
      );

      // Adding an interceptor layer in between to handle cases like logout on getting 401.
      AxiosClient.interceptors.response.use(response => {
        return response
      }, error => {
        try {
          const statusCode = lodash.get(error, 'response.status', null);
          if (statusCode && Number(statusCode) && ~authenticationErrorStatusCodes.indexOf(Number(statusCode))) {
            setToken(null);
          }
          return Promise.reject(error)
        } catch (e) {
          console.log(e);
        }
      });

      AxiosClient(config)
      .then(res => {
        if (res && ~validStatusCodes.indexOf(res.status)) {
          resolve(res.data)
        } else {
          reject(new Error('Something went wrong. Please try again later.'))
        }
      })
      .catch(err => {
        reject(err)
      })
    })
    return requestPromise
  }

}

export default Request
