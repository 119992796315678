import ApiConstant from '../ApiConstant';
import Request from '../Request';
class AuthService {

  static CheckAuth = () => false

  static requestVerificationCode(body) {
    let opts = {
      method: ApiConstant.requestVerificationCode.method,
      url: ApiConstant.requestVerificationCode.url,
      data: body
    }

    return Request.Fire(opts);
  }

  static validateVerificationCode(body) {
    let opts = {
      method: ApiConstant.validateVerificationCode.method,
      url: ApiConstant.validateVerificationCode.url,
      data: body
    }
    return Request.Fire(opts);
  }

  static decodeQueryParams(body) {
    let opts = {
      method: ApiConstant.decodeQueryParams.method,
      url: ApiConstant.decodeQueryParams.url,
      data: body
    }
    return Request.Fire(opts);
  }


}

export default AuthService
