const fiatCurrencyListReducer = (state = [
    // isSample key determines that this is coming from a default state from redux and not from backend
    { isSample: true, country_id: 1, fiat_id: 1, fiat_code: 'INR', fiat_name: 'Indian Rupee', country_flag_url: 'https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1350px-Flag_of_India.svg.png' },
], action) => {
    switch (action.type) {
        case 'SET_FIAT_CURRENCIES':
            return action.payload;

        default:
            return state;
    }
}

export default fiatCurrencyListReducer;
