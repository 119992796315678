import { Box, Grid, FormLabel, Select, MenuItem } from '@material-ui/core';
import { getData } from 'country-list';
import { withStyles } from '@material-ui/styles';
import { useState } from 'react';
import CustomFormButton from 'widgets/CustomFormButton';
import UserService from '../../lib/services/UserService';
import FormHeader from 'widgets/FormHeader';
import CustomFormTextField from 'widgets/CustomFormTextField';
import CustomLinearProgressBar from 'widgets/CustomLinearProgressBar';
import lodash from 'lodash';
import { setUserAddress } from '../../actions'
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';
import Fade from '@mui/material/Fade';

const styles = theme => ({
  mainGrid: {
    padding: "1.5em",
    paddingTop: '1em'
  },
  submitButton: {
    marginTop: "4em"
  },
  avatar: {
    width: '30px',
    height: '30px',
    marginRight: '0.4em'
  },
  menuItem: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  select: {
    "& .MuiSelect-select:focus": {
      backgroundColor: "white",
    },
    "& .MuiInputLabel-outlined": {
      color: theme.palette.secondary.dark
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.secondary.dark
    },
    '&:before': {
      borderColor: theme.palette.secondary.dark,
    },
    '&:after': {
      borderColor: theme.palette.secondary.dark
    }
  },
  fieldDiv: {
    marginBottom: '20px'
  },
  label: {
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
    color: theme.palette.secondary.dark,
  },
  errorText: {
    fontSize: "12px",
    marginTop: "10px",
    color: "#F5222D",
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
    height: '3px'
  }
});

function AddressForm(props) {
  const theme = useTheme();
  const userAddress = useSelector(state => state.userAddress);
  const transactionType = useSelector(state => state.transactionType);
  const [state, setState] = useState(userAddress.state);
  const [country, setCountry] = useState(userAddress.country || "IN");
  const [addressLine1, setAddressLine1] = useState(userAddress.addressLine1);
  const [addressLine2, setAddressLine2] = useState(userAddress.addressLine2);
  const [city, setCity] = useState(userAddress.city);
  const [postalCode, setPostalCode] = useState(userAddress.postalCode);
  const [invalidAddressLine1, setInvalidAddressLine1] = useState(false)
  const [invalidCity, setInvalidCity] = useState(false)
  const [invalidState, setInvalidState] = useState(false)
  const [invalidPostalCode, setInvalidPostalCode] = useState(false)
  const [httpCallInProgress, setHttpCallInProgress] = useState(false);
  const dispatch = useDispatch();

  const [errorText, setErrorText] = useState("");

  const handleAddressLine1Change = (event) => {
    setAddressLine1(event.target.value);
  };

  const handleAddressLine2Change = (event) => {
    setAddressLine2(event.target.value);
  };

  const handleCityChange = (event) => {
    setCity(event.target.value);
  };

  const handleStateChange = (event) => {
    setState(event.target.value);
  };

  const handleCountryChange = (event) => {
    setCountry(event.target.value);
  };

  const handlePostalCodeChange = (event) => {
    setPostalCode(event.target.value);
  };

  let countryList = getData();

  countryList.sort((a, b) => {
    return (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1;
  });

  const getValidationError = (params) => {
    let validForm = true;
    if (!addressLine1) {
      setInvalidAddressLine1(true);
      validForm = false;
    } else {
      setInvalidAddressLine1(false);
    }

    if (!city) {
      setInvalidCity(true);
      validForm = false;
    } else {
      setInvalidCity(false);
    }

    if (!state) {
      setInvalidState(true);
      validForm = false;
    } else {
      setInvalidState(false);
    }

    if (!postalCode) {
      setInvalidPostalCode(true);
      validForm = false;
    } else {
      setInvalidPostalCode(false);
    }

    return validForm;
  }

  const handleSubmit = (event) => {
    setErrorText("");
    const body = {
      addressLine1, addressLine2, state, country, city, postalCode
    };
    const isValidForm = getValidationError(body);
    if (!isValidForm) {
      return;
    }
    setHttpCallInProgress(true);

    UserService.submitUserAddress(body)
      .then((data) => {
        setHttpCallInProgress(false);

        if (data && data.success) {
          dispatch(setUserAddress(body));
          if (lodash.get(props, 'location.state.callingPage') === `CARD_DETAIL`) {
            props.history.push('/carddetail');
            return;
          } else if (lodash.get(props, 'location.state.callingPage') === `CONNECT_BANK_ACCOUNT`) {
            props.history.push('/connectbankaccount');
            return;
          } else if (lodash.get(props, 'location.state.callingPage') === `MENU_PAGE`) {
            props.history.goBack();
            return;
          }
          UserService.getUserProfile()
            .then((data) => {
              if (data && data.status === `success` && data.response) {
                const user = data.response.user || {};
                if (user.kycStatus === `VERIFIED`) {
                  if (transactionType === `SELL`) {
                    props.history.push('/connectbankaccount');
                  } else {
                    props.history.push('/ordersummary');
                  }
                  return;
                }
              }
              props.history.push('/kycform');
            })
            .catch((e) => {
              props.history.push('/kycform');
            });
        }
      })
      .catch((err) => {
        setHttpCallInProgress(false);
        setErrorText("Something went wrong. Please try again later.");
      })
  }

  return (
    <Fade in={true}>
      <Box overflow="auto" className={props.classes.mainGrid}>
        <Grid container direction="column">
          <Grid item>
            <FormHeader titleText="Address" history={props.history} />
          </Grid>
          <Grid item style={{ height: "40px" }}></Grid>
        </Grid>
        <Grid container className={props.classes.fieldDiv}>
          <Grid item xs={12} sm={12}>
            <CustomFormTextField
              size="small"
              labelText="Address Line 1*"
              width="100%"
              value={addressLine1}
              error={invalidAddressLine1}
              onChange={handleAddressLine1Change}
            />
          </Grid>
        </Grid>
        <Grid container className={props.classes.fieldDiv}>
          <Grid item xs={12} sm={12}>
            <CustomFormTextField
              size="small"
              labelText="Address Line 2 (optional)"
              width="100%"
              value={addressLine2}
              onChange={handleAddressLine2Change}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} className={props.classes.fieldDiv}>
          <Grid item xs={6} sm={6}>
            <CustomFormTextField
              size="small"
              labelText="City*"
              width="100%"
              value={city}
              error={invalidCity}
              onChange={handleCityChange}
              placeholder=""
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <CustomFormTextField
              size="small"
              labelText="State*"
              width="100%"
              value={state}
              onChange={handleStateChange}
              error={invalidState}
              placeholder=""
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6}>
            <FormLabel className={props.classes.label}>Country*</FormLabel>
            <Select
              className={props.select}
              size="small"
              value={country}
              onChange={handleCountryChange}
              height="50%"
              style={{
                width: "100%",
                height: "52%",
                color: theme.palette.secondary.dark,
              }}
              variant="outlined"
            >
              {countryList.map((item) => (
                <MenuItem key={`${item.code}`} value={`${item.code}`}>
                  <Box className={props.classes.menuItem}>
                    <span className={props.classes.stateSelect}> {`${item.name}`} </span>
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={6} sm={6}>
            <CustomFormTextField
              size="small"
              labelText="Postal Code*"
              width="100%"
              value={postalCode}
              error={invalidPostalCode}
              onChange={handlePostalCodeChange}
              placeholder=""
            />
          </Grid>
        </Grid>
        {errorText ? <FormLabel className={props.classes.errorText}>{errorText}</FormLabel> : ''}
        <Box className={props.classes.submitButton}>
          {httpCallInProgress ? <CustomLinearProgressBar /> : null}
          <CustomFormButton onClick={handleSubmit} disabled={httpCallInProgress} buttonText="Submit" />
        </Box>
      </Box>
    </Fade>
  )
}

export default withStyles(styles)(AddressForm);
