import ApiConstant from '../ApiConstant';
import Request from '../Request';

class KycService {

  static generateSdkToken() {
    let opts = {
      method: ApiConstant.generateSdKToken.method,
      url: ApiConstant.generateSdKToken.url,
    }

    return Request.Fire(opts);
  }

  static submitKycDocuments(body) {
    let opts = {
      method: ApiConstant.submitKycDocuments.method,
      url: ApiConstant.submitKycDocuments.url,
      data: body
    }

    return Request.Fire(opts);
  }

  static checkKycStatus(checkId) {
    let opts = {
      method: ApiConstant.getKycStatus.method,
      url: `${ApiConstant.getKycStatus.url}/${checkId}`,
    }

    return Request.Fire(opts);
  }

  static checkHigherLimitStatus() {
    let opts = {
      method: ApiConstant.checkHigherLimitStatus.method,
      url: `${ApiConstant.checkHigherLimitStatus.url}`,
    }

    return Request.Fire(opts);
  }

}

export default KycService;
