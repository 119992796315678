import ApiConstant from '../ApiConstant';
import Request from '../Request';

class OrderService {

  static create(data) {
    let opts = {
      method: ApiConstant.orderCreate.method,
      url: ApiConstant.orderCreate.url,
			data: data
    }

    return Request.Fire(opts);
  }

  static getOrderById(orderId, qs, headers) {
    let opts = {
      method: ApiConstant.getOrder.method,
      url: `${ApiConstant.getOrder.url}/${orderId}`,
    }

    if (headers) {
      opts.headers = headers;
    }

    if (qs) {
      opts.params = qs;
    }

    return Request.Fire(opts);
  }

  static getPartnerOrderById(orderId, apiKey) {
    let opts = {
      method: ApiConstant.getPartnerOrder.method,
      url: `${ApiConstant.getPartnerOrder.url}?orderId=${orderId}&apiKey=${apiKey}`
    }

    return Request.Fire(opts);
  }
  
  static updatePartnerOrder(data) {
    let opts = {
      method: ApiConstant.updatePartnerOrder.method,
      url: `${ApiConstant.updatePartnerOrder.url}`,
      data: data
    }

    return Request.Fire(opts);
  }

  static requestOrderVerification(data) {
    let opts = {
      method: ApiConstant.requestOrderVerification.method,
      url: `${ApiConstant.requestOrderVerification.url}`,
      data: data
    }

    return Request.Fire(opts);
  }
  
  static verifyOrder(data) {
    let opts = {
      method: ApiConstant.verifyOrder.method,
      url: `${ApiConstant.verifyOrder.url}`,
      data: data
    }

    return Request.Fire(opts);
  }

  static getOrderList() {
    let opts = {
      method: ApiConstant.getOrder.method,
      url: `${ApiConstant.getOrder.url}`
    }

    return Request.Fire(opts);
  }
  
  static createTransaction(data) {
    let opts = {
      method: ApiConstant.orderTransactionCreate.method,
      url: ApiConstant.orderTransactionCreate.url,
			data: data
    }

    return Request.Fire(opts);
  }

}

export default OrderService;
