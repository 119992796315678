import { withStyles } from '@material-ui/styles';
import { Box, List, ListItem, Grid, ListItemText, ListItemIcon, IconButton } from '@material-ui/core';
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import LoginIcon from '@mui/icons-material/Login';
import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined';
import FormHeader from 'widgets/FormHeader';
import UserService from 'lib/services/UserService';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import BusinessOutlinedIcon from '@material-ui/icons/BusinessOutlined';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import { setToken } from 'lib/Request';
import { useSelector } from 'react-redux';

const styles = theme => ({
  mainDiv: {
    padding: "1.5em",
    paddingTop: '1em',
    width: '100%'
  },
  listItem: {
    borderRadius: "8px",
    margin: "4px",
    paddingRight: "1em"
  },
  listItemText: {
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
    color: theme.palette.secondary.main
  },
  footer: {
    marginTop: "280px"
  },
  icon: {
    color: theme.palette.secondary.main
  },
  wrappingGrid: {
    maxHeight: '58vh',
    overflow: 'auto',
    overflowX: "hidden",
    borderRadius: "10px",
  }
});

function MenuPage(props) {
  const userLoggedIn = useSelector(state => state.userLoggedIn);
  const handleOrderList = () => {
    props.history.push('/orderlist');
  }

  const handleUserProfile = () => {
    props.history.push('/userprofile', { callingPage: "MENU_PAGE" });
  }

  const handleAddress = () => {
    props.history.push('/addressform', { callingPage: "MENU_PAGE" });
  }

  const handleHomeButton = () => {
    props.history.replace('/');
  }

  const handleLogout = () => {
    UserService.logout()
      .then((data) => {
        setToken(null);
        props.history.push('/');
      })
      .catch((err) => {
        props.history.push('/');
      })
  }

  const handleLogin = () => {
    props.history.replace('/emailform', { callingPage: "MENU_PAGE" });
  }

  const handleAbout = () => {
    window.open("https://rampay.io", '_blank'); 
  }

  return (
    <Box overflow="auto" className={props.classes.mainDiv}>
      <Grid container direction="column">
        <FormHeader titleText={`Menu`} history={props.history} hideMenuButton={true} />
      </Grid>
      <Grid container direction="column">
        <Grid item style={{ height: "20px" }}></Grid>
        <Grid item className={props.classes.wrappingGrid}>
          <List component="nav">
            <ListItem button className={props.classes.listItem} onClick={handleHomeButton}>
              <ListItemIcon>
                <HomeOutlinedIcon className={props.classes.icon} />
              </ListItemIcon>
              <ListItemText primary="Home" className={props.classes.listItemText} />
              <IconButton edge="end" aria-label="comments">
                <ArrowForwardIosOutlinedIcon className={props.classes.icon} />
              </IconButton>
            </ListItem>
            {!userLoggedIn ? <ListItem button className={props.classes.listItem} onClick={handleLogin}>
              <ListItemIcon>
                <LoginIcon className={props.classes.icon} />
              </ListItemIcon>
              <ListItemText primary="Login" className={props.classes.listItemText} />
              <IconButton edge="end" aria-label="comments" onClick={handleLogin}>
                <ArrowForwardIosOutlinedIcon className={props.classes.icon} />
              </IconButton>
            </ListItem> : null}
            {userLoggedIn ? <ListItem button className={props.classes.listItem} onClick={handleOrderList}>
              <ListItemIcon>
                <ReceiptOutlinedIcon className={props.classes.icon} />
              </ListItemIcon>
              <ListItemText primary="My Orders" className={props.classes.listItemText} />
              <IconButton edge="end" aria-label="comments">
                <ArrowForwardIosOutlinedIcon className={props.classes.icon} />
              </IconButton>
            </ListItem> : null}
            {userLoggedIn ? <ListItem button className={props.classes.listItem} onClick={handleUserProfile}>
              <ListItemIcon>
                <AccountCircleOutlinedIcon className={props.classes.icon} />
              </ListItemIcon>
              <ListItemText primary="My Profile" className={props.classes.listItemText} />
              <IconButton edge="end" aria-label="comments">
                <ArrowForwardIosOutlinedIcon className={props.classes.icon} />
              </IconButton>
            </ListItem> : null}
            {userLoggedIn ? <ListItem button className={props.classes.listItem} onClick={handleAddress}>
              <ListItemIcon>
                <BusinessOutlinedIcon className={props.classes.icon} />
              </ListItemIcon>
              <ListItemText primary="My Address" className={props.classes.listItemText} />
              <IconButton edge="end" aria-label="comments">
                <ArrowForwardIosOutlinedIcon className={props.classes.icon} />
              </IconButton>
            </ListItem> : null}
            {userLoggedIn ? <ListItem button className={props.classes.listItem} onClick={handleLogout}>
              <ListItemIcon>
                <ExitToAppOutlinedIcon className={props.classes.icon} />
              </ListItemIcon>
              <ListItemText primary="Logout" className={props.classes.listItemText} />
              <IconButton edge="end" aria-label="comments" onClick={handleLogout}>
                <ArrowForwardIosOutlinedIcon className={props.classes.icon} />
              </IconButton>
            </ListItem> : null}
            <ListItem button className={props.classes.listItem} onClick={handleAbout}>
              <ListItemIcon>
                <ExitToAppOutlinedIcon className={props.classes.icon} />
              </ListItemIcon>
              <ListItemText primary="About Rampay" className={props.classes.listItemText} />
              <IconButton edge="end" aria-label="comments" onClick={handleAbout}>
                <ArrowForwardIosOutlinedIcon className={props.classes.icon} />
              </IconButton>
            </ListItem>
            {/* <ListItem button className={props.classes.listItem} onClick={handlePrivacyPolicy}>
              <ListItemIcon>
                <ExitToAppOutlinedIcon className={props.classes.icon} />
              </ListItemIcon>
              <ListItemText primary="Privacy Policy" className={props.classes.listItemText} />
              <IconButton edge="end" aria-label="comments" onClick={handlePrivacyPolicy}>
                <ArrowForwardIosOutlinedIcon className={props.classes.icon} />
              </IconButton>
            </ListItem>
            <ListItem button className={props.classes.listItem} onClick={handleTermsOfUse}>
              <ListItemIcon>
                <ExitToAppOutlinedIcon className={props.classes.icon} />
              </ListItemIcon>
              <ListItemText primary="Terms of Use" className={props.classes.listItemText} />
              <IconButton edge="end" aria-label="comments" onClick={handleTermsOfUse}>
                <ArrowForwardIosOutlinedIcon className={props.classes.icon} />
              </IconButton>
            </ListItem> */}
          </List>
        </Grid>
      </Grid>
    </Box>
  )
}

export default withStyles(styles)(MenuPage);
