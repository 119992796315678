import { useEffect, useState } from 'react';
import FiatSelect from '../../widgets/FiatSelect';
import paymentRejectedAnimation from 'assets/animations/failed.json';
import CryptoSelect from '../../widgets/CryptoSelect';
import CustomLottie from 'widgets/CustomLottie';
import PriceFetch from './widgets/PriceFetch';
import CustomFormButton from '../..//widgets/CustomFormButton';
import PriceDetail from '../..//widgets/PriceDetail';
import UserService from '../../lib/services/UserService';
import { Grid, Box } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { useSelector, useDispatch } from 'react-redux';
import { setUserAddress, setUserLoggedIn, setUserProfile, setFirstTimePageLoaded, setTransactionType } from 'actions';
import Fade from '@mui/material/Fade';
import rampayLogoGif from 'assets/images/rampay.gif';
import MainPageHeader from 'widgets/MainPageHeader';

const styles = theme => ({
  priceDetailDiv: {
    height: '110px',
  },
  mainDiv: {
    padding: '1.5em',
    paddingTop: '1em',
    paddingBottom: '0em',
    width: "100%"
  },
  loaderDiv: {
    marginTop: "50%"
  },
  mainGrid: {
    display: "flex",
    flexDirection: 'column',
    justifyContent: "center",
    alignItems: "center",
    padding: "2em",
    marginTop: "20%",
  },
  mainText: {
    display: "flex",
    justifyContent: "center",
    alignItems: 'center',
    textAlign: "center",
  },
  lottie: {
    display: "flex",
    justifyContent: "center",
    alignItems: 'center',
    textAlign: "center",
  },
});

function BuyForm(props) {
  const fiatListLoaded = useSelector(state => state.fiatListLoaded);
  const cryptoListLoaded = useSelector(state => state.cryptoListLoaded);
  const isFirstTimePageLoaded = useSelector(state => state.firstTimePageLoaded);
  const dispatch = useDispatch();
  const openInAnotherTab = props.openInAnotherTab;
  const fromCurrency = props.fromCurrency;
  const toCurrency = props.toCurrency;
  const defaultAmount = props.defaultAmount;
  const supportedCryptoCurrenciesList = props.supportedCryptoCurrenciesList;
  const mode = props.mode;
  const disableCryptoChange = props.disableCryptoChange;
  const disableFiatChange = props.disableFiatChange;
  const disableFiatAmountChange = props.disableFiatAmountChange;
  const fetchOrderDetailError = props.fetchOrderDetailError;
  const [httpCallInProgress, setHttpCallInProgress] = useState(true);


  useEffect(() => {
    setTimeout(() => {
      if (!isFirstTimePageLoaded) {
        dispatch(setFirstTimePageLoaded(true));
      }
    }, 2000);
    dispatch(setTransactionType("BUY"));
    UserService.getUserProfile()
      .then((data) => {
        if (data && data.status === `success` && data.response) {
          const user = data.response.user || {};
          dispatch(setUserLoggedIn(true));
          if (user.address && Object.keys(user.address)) {
            dispatch(setUserAddress({
              addressLine1: user.address.addressLine1,
              addressLine2: user.address.addressLine2,
              state: user.address.state,
              country: user.address.country,
              city: user.address.city,
              postalCode: user.address.postalCode
            }));
          }
          const userData = {
            firstName: user.firstName || "",
            lastName: user.lastName || "",
            dateOfBirth: user.dateOfBirth || "",
            isdCode: user.isdCode || "",
            phoneNumber: user.phoneNumber || "",
          };
          dispatch(setUserProfile(userData));
        } else {
          dispatch(setUserLoggedIn(false));
        }
      })
      .catch((err) => {
        dispatch(setUserLoggedIn(false));
      })
  }, [dispatch, isFirstTimePageLoaded]);

  const handleClick = () => {
    if (openInAnotherTab === "true") {
      window.open("https://buy.rampay.io", '_blank');
    } else {
      props.history.push("/walletform");
    }
  };

  const setHttpCallInProgressFromComponentAsTrue = () => {
    setHttpCallInProgress(true);
  }

  const setHttpCallInProgressFromComponentAsFalse = () => {
    setHttpCallInProgress(false);
  }

  return (
    fetchOrderDetailError ?
      <Box className={props.classes.mainDiv}>
        <Grid container direction="column" className={props.classes.mainGrid}>
          <Grid item className={props.classes.lottie}>
            <CustomLottie
              lottie={paymentRejectedAnimation}
              loop={false}
              width={100}
              hieght={100}
            />
          </Grid>
          <Grid item>
            <span className={props.classes.mainText}>{fetchOrderDetailError}</span>
          </Grid>
          {/* <Grid item className={props.classes.actionButton}>
            {getKycStatusAction()}
          </Grid> */}
        </Grid>
      </Box> :
      <Fade in={true} timeout={500}>
        <Box className={props.classes.mainDiv}>
          {!(fiatListLoaded && cryptoListLoaded && isFirstTimePageLoaded) ? getLoader() :
            <Grid container direction={'column'}>
              <Grid item>
                <MainPageHeader history={props.history} mode={mode} selectedTab={"buy"} />
              </Grid>
              <Grid item style={{ height: "40px" }}></Grid>
              <Grid item >
                <FiatSelect
                  fromCurrency={fromCurrency}
                  disableFiatChange={disableFiatChange}
                  disableFiatAmountChange={disableFiatAmountChange}
                  defaultAmount={defaultAmount}
                />
              </Grid>
              <Grid item style={{ height: "10px" }}></Grid>
              <Grid item >
                <CryptoSelect
                  toCurrency={toCurrency}
                  disableCryptoChange={disableCryptoChange}
                  supportedCryptoCurrenciesList={supportedCryptoCurrenciesList}
                />
              </Grid>
              <Grid item style={{ height: "20px" }}></Grid>
              <Grid item className={props.classes.priceDetailDiv}>
                <PriceFetch setHttpCallInProgressFromComponentAsTrue={setHttpCallInProgressFromComponentAsTrue} setHttpCallInProgressFromComponentAsFalse={setHttpCallInProgressFromComponentAsFalse} />
                <PriceDetail />
              </Grid>
              <Grid item style={{ height: "25px" }}></Grid>
              <Grid item>
                <CustomFormButton onClick={handleClick} disabled={httpCallInProgress} buttonText="Continue to buy" />
              </Grid>
            </Grid>
          }
        </Box>
      </Fade>
  )

  function getLoader() {
    return (
      <Grid container className={props.classes.loaderDiv}>
        <Grid item xs={2} sm={2}></Grid>
        <Grid item xs={8} sm={8} style={{ textAlign: "center", margin: "auto" }}>
          <img src={rampayLogoGif} alt={"Rampay"} width="200px" height="200px" />
          <FiatSelect fromCurrency={fromCurrency} defaultAmount={defaultAmount} visibility="hidden" />
          <CryptoSelect toCurrency={toCurrency} supportedCryptoCurrenciesList={supportedCryptoCurrenciesList} visibility="hidden" />
        </Grid>
        <Grid item xs={2} sm={2}></Grid>
      </Grid>
    );
  }
}



export default withStyles(styles)(BuyForm);
