const fiatAmountForSellReducer = (state = false, action) => {
  switch (action.type) {
      case 'SET_FIAT_AMOUNT_FOR_SELL':
          return action.payload;

      default:
          return state;
  }
}


export default fiatAmountForSellReducer;
