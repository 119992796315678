import { Box, OutlinedInput, InputAdornment, Avatar } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { setWalletAddress, setWalletAddressMap } from '../../../actions';
import Typography from '@material-ui/core/Typography';
import { useState } from 'react';
import util from '../../../lib/util';
import { useTheme } from '@material-ui/core/styles';

function WalletField(props) {
  let errorMessage = "";
  const theme = useTheme();
  const dispatch = useDispatch();
  const walletAddressMap = useSelector(state => state.walletAddressMap);
  const walletAddress = useSelector(state => state.walletAddress);
  const selectedCryptoCurrency = useSelector(state => state.selectedCryptoCurrency);
  const defaultErrorText = `* This is not a valid ${selectedCryptoCurrency.name} wallet address`;
  const disableWalletAddressChange = props.disableWalletAddressChange === "true";

  if (walletAddress && !util.isValidCryptoAddress(walletAddress, selectedCryptoCurrency.code)) {
    errorMessage = defaultErrorText;
  }

  const [errorText, setErrorText] = useState(errorMessage);

  const handleChange = (event) => {
    /**
     * Delete the wallet address from redux map in case it is edited by user
     */
    if (walletAddressMap && walletAddressMap[selectedCryptoCurrency.code.toLowerCase()]) {
      delete walletAddressMap[selectedCryptoCurrency.code.toLowerCase()];
      dispatch(setWalletAddressMap(walletAddressMap || {}));
    }
    if (util.isValidCryptoAddress(event.target.value, selectedCryptoCurrency.code)) {
      setErrorText("");
    } else {
      setErrorText(defaultErrorText);
    }
    dispatch(setWalletAddress(event.target.value));
  };

  const subTextStyle = {
    fontSize: "12px",
    marginTop: "10px",
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
    color: theme.palette.secondary.main,
  };

  return (
    <Box>
      <OutlinedInput
        id="outlined-adornment-weight"
        variant="outlined"
        value={walletAddress}
        onChange={handleChange}
        disabled={disableWalletAddressChange}
        aria-describedby="outlined-weight-helper-text"
        style={{
          fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
          width: '100%',
          backgroundColor: theme.palette.secondary.light
        }}
        startAdornment={
          <InputAdornment position="start">
            <Avatar alt="Bitcoin" src={selectedCryptoCurrency.image} />
          </InputAdornment>
        }
        inputProps={{
          'aria-label': 'weight',
        }}
        labelWidth={0}
        placeholder={`Your ${selectedCryptoCurrency.code.toUpperCase()} wallet address`}
      />
      <Typography style={subTextStyle}>* Enter a {util.capitalizeFirstLetter(selectedCryptoCurrency.name)} wallet address that you own and have access to</Typography>
      <Typography style={errorTextStyle}>{errorText}</Typography>
      <div id="ondifo-mount">

      </div>
    </Box>
  )

}

const errorTextStyle = {
  height: "2px",
  fontSize: "12px",
  marginTop: "10px",
  color: "#F5222D",
  fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif"
}

export default WalletField;
