import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import CustomLayout from '../../widgets/CustomLayout'
import WalletForm from '../WalletForm'
import BuyForm from 'components/BuyForm'
import EmailForm from 'components/EmailForm'
import VerifyEmailOTP from 'components/VerifyEmailOTP'
import UserProfile from 'components/UserProfile';
import KycForm from 'components/KycForm';
import OrderSummary from 'components/OrderSummary';
import CheckKyc from 'components/CheckKyc';
import FiatSelectionForm from 'components/FiatSelectionForm';
import CryptoSelectionForm from 'components/CryptoSelectionForm';
import PaymentMethodSelectionForm from 'components/PaymentMethodSelectionForm';
import CardDetail from 'components/CardDetail';
import AuthorizePayment from 'components/AuthorizePayment';
import CheckPayment from 'components/CheckPayment';
import { MemoryRouter, Route, Switch } from 'react-router-dom';
import MenuPage from 'components/MenuPage';
import AddressForm from 'components/AddressForm';
import OrderList from 'components/OrderList';
import Payment3dsPage from 'components/Payment3dsPage';
import { useLocation } from 'react-router-dom';
import util from '../../lib/util';
import ReactGA from 'react-ga';
import { setPartnerApiKey, setPrimaryColor, setWalletAddressMap, setIsDarkTheme, setPartnerOrderId } from 'actions';
import AuthService from 'lib/services/AuthService';
import OrderService from 'lib/services/OrderService';
import lodash from 'lodash';
import CheckHigherLimitKyc from 'components/CheckHigherLimitKyc';
import SellForm from 'components/SellForm';
import ConnectBankAccount from 'components/ConnectBankAccount';
import OrderSummaryForSell from 'components/OrderSummaryForSell';
import DestinationWalletPayment from 'components/DestinationWalletPayment';
import DirectCardCheckout from 'components/DirectCardCheckout';

function setQueryParamInRedux({ apiKey, walletAddress, primaryColor, isDarkTheme, partnerOrderId, dispatch }) {
  if (apiKey) {
    dispatch(setPartnerApiKey(apiKey));
  }
  if (partnerOrderId) {
    dispatch(setPartnerOrderId(partnerOrderId));
  }
  if (walletAddress) {
    walletAddress = walletAddress.trim();
    let walletAddressList = walletAddress.split(",");
    const walletAddressMap = {};
    walletAddressList.forEach((item) => {
      const cryptoAndAddressPair = item.split(":");
      if (cryptoAndAddressPair && cryptoAndAddressPair[0] && cryptoAndAddressPair[1]) {
        walletAddressMap[cryptoAndAddressPair[0].toLowerCase()] = cryptoAndAddressPair[1];
      }
    });
    dispatch(setWalletAddressMap(walletAddressMap));
  }
  if (primaryColor && util.isValidHexCode(primaryColor)) {
    dispatch(setPrimaryColor(`#${primaryColor}`));
  }
  if (isDarkTheme && isDarkTheme === "true") {
    dispatch(setIsDarkTheme(true));
  }
}

function MainForm(props) {
  const dispatch = useDispatch();
  const search = useLocation().search;
  const encodedParams = new URLSearchParams(search).get('q');
  const [paramsFetchedFromBackend, setParamsFetchedFromBackend] = useState(false);
  const [openInAnotherTab, setOpenInAnotherTab] = useState(new URLSearchParams(search).get('openInAnotherTab'));
  const [fromCurrency, setFromCurrency] = useState(new URLSearchParams(search).get('from'));
  const [toCurrency, setToCurrency] = useState(new URLSearchParams(search).get('to'));
  const [defaultAmount, setDefaultAmount] = useState(new URLSearchParams(search).get('amt'));
  const [fetchOrderDetailError, setFetchOrderDetailError] = useState("");
  const [supportedCryptoCurrenciesList, setSupportedCryptoCurrenciesList] = useState(new URLSearchParams(search).get('cl'));
  const [mode, setMode] = useState(new URLSearchParams(search).get('mode'));
  const [disableCryptoChange, setDisableCryptoChange] = useState(new URLSearchParams(search).get('disableCryptoChange'));
  const [disableFiatChange, setDisableFiatChange] = useState(new URLSearchParams(search).get('disableFiatChange'));
  const [disableFiatAmountChange, setDisableFiatAmountChange] = useState(new URLSearchParams(search).get('disableFiatAmountChange'));
  const [disableWalletAddressChange, setDisableWalletAddressChange] = useState(new URLSearchParams(search).get('disableWalletAddressChange'));
  const apiKey = new URLSearchParams(search).get('apiKey');
  const walletAddress = new URLSearchParams(search).get('walletAddress');
  const primaryColor = new URLSearchParams(search).get('primaryColor');
  const isDarkTheme = new URLSearchParams(search).get('isDarkTheme');
  const partnerOrderId = new URLSearchParams(search).get('orderId');
  const [directCheckout, setDirectCheckout] = useState(false);

  useEffect(() => {
    async function fetchData() {
      if (encodedParams) {
        const params = await AuthService.decodeQueryParams({ encodedParams });
        setQueryParamInRedux({ ...params, dispatch });
        if (params.openInAnotherTab) setOpenInAnotherTab(params.openInAnotherTab);
        if (params.from) setFromCurrency(params.from);
        if (params.to) setToCurrency(params.to);
        if (params.amt) setDefaultAmount(params.amt);
        if (params.cl) setSupportedCryptoCurrenciesList(params.cl);
        if (params.mode) setMode(params.mode);
        if (params.disableCryptoChange) setDisableCryptoChange(params.disableCryptoChange);
        if (params.disableFiatChange) setDisableFiatChange(params.disableFiatChange);
        if (params.disableFiatAmountChange) setDisableFiatAmountChange(params.disableFiatAmountChange);
        if (params.disableWalletAddressChange) setDisableWalletAddressChange(params.disableWalletAddressChange);
      } else {
        setQueryParamInRedux({ apiKey, walletAddress, primaryColor, isDarkTheme, partnerOrderId, dispatch });
      }
      if (partnerOrderId && apiKey) {
        try {
          const order = await OrderService.getPartnerOrderById(partnerOrderId, apiKey);
          if ([`COMPLETED`, `PROCESSING_CRYPTO`, `RELEASING_CRYPTO`, `FAILED`].indexOf(order.status) > -1) {
            dispatch(setPartnerOrderId(null));
          } else {
            setFromCurrency(order.fiat_currency?.code);
            setToCurrency(order.crypto_currency?.code);
            setDefaultAmount(order.fiat_currency?.amount);
            const walletAddressMap = {};
            walletAddressMap[order.crypto_currency?.code.toLowerCase()] = order.wallet_addr;
            dispatch(setWalletAddressMap(walletAddressMap));
            setMode("buy");
            setDisableCryptoChange("true");
            setDisableFiatChange("true");
            setDisableFiatAmountChange("true");
            setDisableWalletAddressChange("true");
            if (order.status === `INITIATED`) {
              setDirectCheckout(true);
            }
          }
        } catch (err) {
          dispatch(setPartnerOrderId(null));
          const errorMessage = lodash.get(err, 'response.data.message', "Something went wrong. Please try again later.");
          setFetchOrderDetailError(errorMessage);
        }
      }
      setParamsFetchedFromBackend(true);
    }
    fetchData();
  }, [encodedParams, apiKey, walletAddress, primaryColor, isDarkTheme, partnerOrderId, dispatch]);

  const trackingId = "G-sample"
  ReactGA.initialize(trackingId);
  ReactGA.pageview(window.location.pathname + window.location.search);

  return (
    <CustomLayout>
      <MemoryRouter>
        <Switch>
          {mode === "sell" ? 
          <Route exact path='/'
          render={(props) => paramsFetchedFromBackend ? <SellForm
            openInAnotherTab={openInAnotherTab}
            fromCurrency={fromCurrency}
            toCurrency={toCurrency}
            supportedCryptoCurrenciesList={supportedCryptoCurrenciesList}
            defaultAmount={defaultAmount}
            mode={mode}
            disableCryptoChange={disableCryptoChange}
            disableFiatChange={disableFiatChange}
            disableFiatAmountChange={disableFiatAmountChange}
            fetchOrderDetailError={fetchOrderDetailError}
            {...props}
          /> : null}>
        </Route> : (directCheckout ? <Route exact path='/'
            component={DirectCardCheckout}>
          </Route> : <Route exact path='/'
              render={(props) => paramsFetchedFromBackend ? <BuyForm
                openInAnotherTab={openInAnotherTab}
                fromCurrency={fromCurrency}
                toCurrency={toCurrency}
                supportedCryptoCurrenciesList={supportedCryptoCurrenciesList}
                defaultAmount={defaultAmount}
                mode={mode}
                disableCryptoChange={disableCryptoChange}
                disableFiatChange={disableFiatChange}
                disableFiatAmountChange={disableFiatAmountChange}
                fetchOrderDetailError={fetchOrderDetailError}
                {...props}
              /> : null}>
            </Route>)
            }
          <Route exact path='/sell'
            render={(props) => paramsFetchedFromBackend ? <SellForm
              openInAnotherTab={openInAnotherTab}
              fromCurrency={fromCurrency}
              toCurrency={toCurrency}
              supportedCryptoCurrenciesList={supportedCryptoCurrenciesList}
              defaultAmount={defaultAmount}
              mode={mode}
              disableCryptoChange={disableCryptoChange}
              disableFiatChange={disableFiatChange}
              disableFiatAmountChange={disableFiatAmountChange}
              fetchOrderDetailError={fetchOrderDetailError}
              {...props}
            /> : null}>
          </Route>
          <Route exact path='/walletform'
            render={(props) =>
              <WalletForm
                disableWalletAddressChange={disableWalletAddressChange}
                {...props}
              />
            }>
          </Route>
          <Route exact path='/emailform'
            component={EmailForm}>
          </Route>
          <Route exact path='/verifyemailotp'
            component={VerifyEmailOTP}>
          </Route>
          <Route exact path='/userprofile'
            component={UserProfile}>
          </Route>
          <Route exact path='/kycform'
            component={KycForm}>
          </Route>
          <Route exact path='/ordersummary'
            component={OrderSummary}>
          </Route>
          <Route exact path='/fiatselectionform'
            component={FiatSelectionForm}>
          </Route>
          <Route exact path='/cryptoselectionform'
            component={CryptoSelectionForm}>
          </Route>
          <Route exact path='/paymentmethodselectionform'
            component={PaymentMethodSelectionForm}>
          </Route>
          <Route exact path='/checkkyc'
            component={CheckKyc}>
          </Route>
          <Route exact path='/checkHigherLimitKyc'
            component={CheckHigherLimitKyc}>
          </Route>
          <Route exact path='/carddetail'
            component={CardDetail}>
          </Route>
          <Route exact path='/authorizepayment'
            component={AuthorizePayment}>
          </Route>
          <Route exact path='/checkpayment'
            component={CheckPayment}>
          </Route>
          <Route exact path='/menupage'
            component={MenuPage}>
          </Route>
          <Route exact path='/addressform'
            component={AddressForm}>
          </Route>
          <Route exact path='/orderlist'
            component={OrderList}>
          </Route>
          <Route exact path='/payment3dsPage'
            component={Payment3dsPage}>
          </Route>
          <Route exact path='/connectbankaccount'
            component={ConnectBankAccount}>
          </Route>
          <Route exact path='/ordersummaryforsell'
            component={OrderSummaryForSell}>
          </Route>
          <Route exact path='/destinationwalletpayment'
            component={DestinationWalletPayment}>
          </Route>
        </Switch>
      </MemoryRouter>
    </CustomLayout>
  )
}

export default MainForm;
