import ApiConstant from '../ApiConstant';
import Request from '../Request';
class UserService {

  static submitUserProfile(body) {
    let opts = {
      method: ApiConstant.submitUserProfile.method,
      url: ApiConstant.submitUserProfile.url,
      data: body
    }

    return Request.Fire(opts);
  }

  static submitUserAddress(body) {
    let opts = {
      method: ApiConstant.submitUserAddress.method,
      url: ApiConstant.submitUserAddress.url,
      data: body
    }

    return Request.Fire(opts);
  }
  
  static logout() {
    let opts = {
      method: ApiConstant.logout.method,
      url: ApiConstant.logout.url,
    }
    return Request.Fire(opts);
  }

  static getUserProfile() {
    let opts = {
      method: ApiConstant.getUserProfile.method,
      url: ApiConstant.getUserProfile.url,
    }

    return Request.Fire(opts);
  }
  
  static getPhoneCodes() {
    let opts = {
      method: ApiConstant.getPhoneIsdCodes.method,
      url: ApiConstant.getPhoneIsdCodes.url
    }

    return Request.Fire(opts);
  }

  static getTransactionLimits(currencyCode) {
    let opts = {
      method: ApiConstant.getTransactionLimits.method,
      url: `${ApiConstant.getTransactionLimits.url}?currency=${currencyCode}`
    }

    return Request.Fire(opts);
  }
  
  static getKycOnboardingUrl() {
    let opts = {
      method: ApiConstant.getKycOnboardingUrl.method,
      url: `${ApiConstant.getKycOnboardingUrl.url}`
    }

    return Request.Fire(opts);
  }

}

export default UserService;
