import { Box, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import SelectSearch from 'react-select-search';
import { useSelector, useDispatch } from 'react-redux';
import { setCryptoAmountToSell, setPriceParamChanged, setSelectedCryptoCurrency, setSelectedCryptoCurrencyToSell, setWalletAddress } from '../../actions';
import lodash from 'lodash';
import Fade from '@mui/material/Fade';

import fuzzySearch from './fuzzySearch';
import FormHeader from 'widgets/FormHeader';

const styles = theme => ({
  mainGrid: {
    padding: "1.5em",
    paddingTop: "1em",
  },
});

function CryptoSelectionForm(props) {
  const cryptoCurrencyList = useSelector(state => state.cryptoCurrencyList);
  const walletAddressMap = useSelector(state => state.walletAddressMap);
  const payingCrypto = lodash.get(props, 'location.state.payingCrypto');
  const filteredList = [];
  cryptoCurrencyList.forEach((currency) => {
    currency.value = `${currency.code}_${currency.network || ""}`
    if (payingCrypto) {
      if (currency.sell_allowed === true) {
        filteredList.push(currency);
      }
    } else {
      filteredList.push(currency);
    }
  })

  const dispatch = useDispatch();

  function renderValue(props, option, snapshot, className) {
    const imgStyle = {
      borderRadius: '50%',
      verticalAlign: 'middle',
      marginRight: 10,
    };
    const chainLabelStyle = {
      fontSize: 10,
      color: "white",
      display: "inline-block",
      backgroundColor: "#bcbcbc",
      padding: "0.2em",
      borderRadius: "4px"
    };

    return (
      <button {...props} className={className} type="button">
        <Grid container id={option.id}>
          <Grid item xs={2}>
            <img alt="" style={imgStyle} width="35" height="35" src={option.image} />
          </Grid>
          <Grid item xs={8}>
            <Typography style={{ fontSize: 15, display: 'flex', marginTop: '5px' }}>
              {option.name}
            </Typography>
            {option.display_chain ?
              <Typography style={chainLabelStyle}>
                {option.display_chain?.toUpperCase()}
              </Typography>
              : null}
          </Grid>
          <Grid item xs={2}>
            <Typography style={{ fontSize: 15, marginTop: '5px' }}>
              {option.code}
            </Typography>
          </Grid>
        </Grid>
      </button>
    );
  }

  const handleClick = (value) => {
    const selectedCryptoCurrency = lodash.find(filteredList, function (currency) {
      return currency.value === value;
    });
    if (walletAddressMap && walletAddressMap[selectedCryptoCurrency.code.toLowerCase()]) {
      dispatch(setWalletAddress(walletAddressMap[selectedCryptoCurrency.code.toLowerCase()]));
    } else {
      dispatch(setWalletAddress(""));
    }
    if (payingCrypto) {
      dispatch(setSelectedCryptoCurrencyToSell(selectedCryptoCurrency));
      dispatch(setCryptoAmountToSell(selectedCryptoCurrency.default_amount));
      dispatch(setPriceParamChanged('CRYPTO_SELECT'));
    } else {
      dispatch(setSelectedCryptoCurrency(selectedCryptoCurrency))
    }
    props.history.goBack();
  }


  return (
    <Fade in={true}>
      <Box overflow="auto" className={props.classes.mainGrid}>
        <Grid container direction="column">
          <Grid item>
            <FormHeader titleText="Select Cryptocurrency" history={props.history} />
          </Grid>
        </Grid>
        <Grid container style={formControlStyle}>
          <Grid item xs={12}>
            <SelectSearch
              className="select-search select-search--multiple"
              options={filteredList}
              renderOption={renderValue}
              search
              filterOptions={fuzzySearch}
              placeholder="Search"
              printOptions="always"
              onChange={handleClick}
            />
          </Grid>
        </Grid>
      </Box>
    </Fade>
  )
}

const formControlStyle = {
  width: '100%',
  height: '50%',
}

export default withStyles(styles)(CryptoSelectionForm);
