const cryptoCurrencyListReducer = (state = [
  // isSample key determines that this is coming from a default state from redux and not from backend
  { isSample: true, id: 1, code: 'BTC', name: 'Bitcoin', "is_enabled": "ENABLED", image: 'https://e1.pngegg.com/pngimages/781/878/png-clipart-button-ui-requests-bitcoin-logo-icon.png' },
], action) => {
  switch (action.type) {
    case 'SET_CRYPTO_CURRENCIES':
      return action.payload;

    default:
      return state;
  }
}

export default cryptoCurrencyListReducer;
