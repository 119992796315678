const priceParamChanged = (state = false, action) => {
    switch (action.type) {
        case 'SET_PRICE_PARAM_CHANGED':
            return action.payload;
  
        default:
            return state;
    }
  }
  
  
  export default priceParamChanged;
  