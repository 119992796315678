import { Box, Grid, Card, IconButton, Typography, CardContent, FormControlLabel, FormLabel, Link } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';
import CustomFormButton from 'widgets/CustomFormButton';
import PaymentService from '../../lib/services/PaymentService';
import OrderService from '../../lib/services/OrderService';
import { useSelector, useDispatch } from 'react-redux';
import FormHeader from 'widgets/FormHeader';
import CustomFormTextField from 'widgets/CustomFormTextField';
import CustomLinearProgressBar from 'widgets/CustomLinearProgressBar';
import util from '../../lib/util';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import Fade from '@mui/material/Fade';
import VerifyEmailField from 'widgets/VerifyEmailField';
import CustomCheckBox from 'widgets/CustomCheckBox';
import { styled } from "@mui/material/styles";
import Util from '../../lib/util';
import { setOrderId } from 'actions';

const styles = theme => ({
  mainGrid: {
    padding: "1.5em",
    paddingTop: '1em',
    width: '100%'
  },
  textGrid: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  textLabelGrid: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  tooltip: {
    fontSize: "25px"
  },
  subText: {
    fontSize: "10px",
    marginTop: "10px",
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif"
  },
  errorText: {
    fontSize: "12px",
    marginTop: "5px",
    marginBottom: "5px",
    color: "black",
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
    display: "flex",
    justifyContent: "center",
    height: '10px'
  },
  formDiv: {
    marginTop: '0.3em',
    marginBottom: '10px'
  },
  customFormButton: {
    padding: 0,
  },
  addressHeading: {
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
    fontSize: "14px",
  },
  walletText: {
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
    fontSize: "12px",
    inlineSize: "300px",
    height: '40px'
  },
  addressText: {
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
    fontSize: "14px",
    inlineSize: "300px",
    height: '40px'
  },
  editButton: {
    display: "flex",
    justifyContent: "flex-end"
  },
  payViaText: {
    color: theme.palette.secondary.dark,
    marginRight: "1em",
    marginTop: "10px",
    fontSize: "14px"
  },
  payViaGrid: {
    marginTop: "0.6em",
  },
  checkBoxLabel: {
    fontSize: "12px",
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
    color: theme.palette.secondary.dark
  },
});

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      format="##/##"
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  // name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

function DirectCardCheckout(props) {
  const [cardNumber, setCardNumber] = useState("");
  const [cardExpiry, setCardExpiry] = useState("");
  const [checked, setChecked] = useState(false);
  const [cvvCode, setCvvCode] = useState("");
  const [invalidCardDetail, setInvalidCardDetail] = useState(false);
  const [invalidCardExpiry, setInvalidCardExpiry] = useState(false);
  const [invalidCvvCode, setInvalidCvvCode] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [httpCallInProgress, setHttpCallInProgress] = useState(false);
  const orderId = useSelector(state => state.partnerOrderId);
  const partnerApiKey = useSelector(state => state.partnerApiKey);
  const [orderVerified, setOrderVerified] = useState(false);
  const [allowVerification, setAllowVerification] = useState(false);
  const [optLabelMessage, setOptLabelMessage] = useState("Sending verification OTP to your email...");
  const [otp, setOtp] = useState("");
  const [userAddress, setUserAddress] = useState({});
  const formattedAddress = util.generateFormattedAddress(userAddress);
  const [userName, setUserName] = useState("");
  const [walletAddress, setWalletAddress] = useState("");
  const [cryptoCurrencyCode, setCryptoCurrencyCode] = useState("");
  const [formattedFiatAmount, setFormattedFiatAmount] = useState("");
  const [authToken, setAuthToken] = useState("");
  const dispatch =  useDispatch(); 


  const handleCheckBoxChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleCardNumberChange = (event) => {
    setCardNumber(event.target.value);
  };

  const handleOTPChange = (value) => {
    setOtp(value);
  }

  const handleCardExpiryChange = (event) => {
    setCardExpiry(event.target.value);
  };

  const handleCvvCodeChange = (event) => {
    setCvvCode(event.target.value);
  };

  useEffect(() => {
    const body = {
      orderId, partnerApiKey
    };
    OrderService.requestOrderVerification(body)
      .then((response) => {
        if (response && response.status === `success`) {
          setOptLabelMessage(response.message);
          setAllowVerification(true);
          setErrorText("You need to verify your email before adding card details");
          return;
        }
        setOptLabelMessage("We could not verify this order. Please try again later.");
        setAllowVerification(false);
      })
      .catch((e) => {
        setOptLabelMessage("Order verification failed. Please try again later.");
        setAllowVerification(false);
      });
  }, [orderId, partnerApiKey]);

  const handleVerifySubmit = () => {
    const body = {
      orderId,
      partnerApiKey,
      otp
    };
    OrderService.verifyOrder(body)
      .then((response) => {
        if (response && response.status === `success`) {
          const user = response.response?.user || {};
          const order = response.response?.order || {};
          setOptLabelMessage("OTP verified successfully.");
          setOrderVerified(true);
          setErrorText("");
          setUserName(`${Util.capitalizeFirstLetter(user.firstName)} ${Util.capitalizeFirstLetter(user.lastName)}`);
          setUserAddress(user.address || {});
          setAuthToken(user.authToken);
          setWalletAddress(order.walletAddress);
          setCryptoCurrencyCode(order.cryptoCode);
          setFormattedFiatAmount(`${order.fiatSymbol}${Number(order.fiatAmount)?.toFixed(2)}`);
          dispatch(setOrderId(order.id));
          return;
        }
        setOptLabelMessage("We could not verify this order. Please try again later.");
        setOrderVerified(false);
      })
      .catch((e) => {
        setOptLabelMessage("Order verification failed. Please try again later.");
        setOrderVerified(false);
      });
  };

  const getValidationError = (params) => {
    let validForm = true;
    if (!cardNumber) {
      setInvalidCardDetail(true);
      validForm = false;
    } else {
      setInvalidCardDetail(false);
    }
    if (!cardExpiry || !moment(cardExpiry, 'MM/YY').isValid()) {
      setInvalidCardExpiry(true);
      validForm = false;
    } else {
      setInvalidCardExpiry(false);
    }
    if (!cvvCode) {
      setInvalidCvvCode(true);
      validForm = false;
    } else {
      setInvalidCvvCode(false);
    }
    return validForm;
  }

  const handlePaymentSubmit = (event) => {
    setErrorText("");
    const body = {
      payment_method: {
        number: cardNumber,
        year: "20" + cardExpiry.substring(2, 4),
        month: cardExpiry.substring(0, 2),
        cvv: cvvCode
      },
      order_id: String(orderId),
      partnerApiKey: partnerApiKey,
    };
    const isValidForm = getValidationError(body);
    if (!isValidForm) {
      return;
    }
    setHttpCallInProgress(true);

    PaymentService.createPayment(body, {"x-rl-auth-token": authToken}/* Auth Header */)
      .then((data) => {
        setHttpCallInProgress(false);
        if (data) {
          if (data && data.auth_response) {
            const authorization3dsUrl = lodash.get(data, 'auth_response.authorization3dsUrl', "");
            if (authorization3dsUrl) {
              let authWindow = window.open(authorization3dsUrl, "_blank");
              props.history.push('/checkpayment', {
                authToken,
                successText: "Payment received successfully. Your crypto is on its way. You can close this window.",
                failureText: "Payment failed. Please try again. You can close this window.",
                partnerAPIKeyFilter: partnerApiKey,
                isCheckoutFlow: true,
                authWindow: authWindow,
              });
              return;
            }
          } else {
            setErrorText("Looks like we are facing some issue with our banking partners. Please try again later.");
          }
        } else {
          setErrorText("Looks like we are facing some issue with our banking partners. Please try again later.");
        }
      })
      .catch((err) => {
        const errorMessage = lodash.get(err, 'response.data.message', null);
        setErrorText(errorMessage || "Something went wrong. Please try again later.");
      })
  }

  const CardContentNoPadding = styled(CardContent)(`
    &:last-child {
      padding-bottom: 0;
      padding-top: 5px;
    }
  `);

  return (
    <Fade in={true}>
      <Box overflow="auto" className={props.classes.mainGrid}>
        <Grid container direction="column">
          <Grid item>
            <FormHeader titleText="Complete Payment" history={props.history} hideBackButton={true} hideMenuButton={true} />
          </Grid>
          {!orderVerified ? <Grid item style={{ height: "20px" }}></Grid> : null}
        </Grid>
        {!orderVerified ?
          <Grid container>
            <Grid item xs={8} sm={8}>
              <VerifyEmailField setOTP={handleOTPChange} placeholderText={"Enter Verification Code"} customStyle={{ borderRadius: "0" }} />
            </Grid>
            <Grid item xs={4} sm={4} style={{ paddingLeft: "0px" }}>
              <Box style={{ height: "100%" }}>
                <CustomFormButton customDivStyle={{ height: "100%", borderRaius: "0" }} customButtonStyle={{ height: "100%", borderRadius: "0" }} onClick={handleVerifySubmit} disabled={allowVerification && otp.length !== 6} buttonText="Verify" />
              </Box>
            </Grid>
          </Grid>
          : null}
        {!orderVerified ?
          <Grid container>
            <Grid item xs={12} sm={12}>
              <FormLabel className={props.classes.subText}>* {optLabelMessage}</FormLabel>
            </Grid>
          </Grid>
          : null}
        {!orderVerified ?
          <Grid container style={{height: "80px"}}>
          </Grid>
          : null}
        {orderVerified ?
          <Card variant='outlined'>
            <CardContentNoPadding>
              <Box>
                <Grid container>
                  <Grid item xs={12} sm={12}>
                    <Typography variant="body2" className={props.classes.addressHeading}>
                      Buying {cryptoCurrencyCode}
                    </Typography>
                  </Grid>
                </Grid>
                <Typography variant="body2" color="textSecondary" component="p" className={props.classes.walletText}>
                  {walletAddress}
                </Typography>
              </Box>
            </CardContentNoPadding>
          </Card> : null}
        {orderVerified ?
          <Box>
            <Card variant="outlined" style={{ marginTop: "8px", marginBottom: "8px", height: "100px" }}>
              <CardContentNoPadding>
                <Grid container>
                  <Grid item xs={6} sm={6}>
                    <Typography variant="body2" className={props.classes.addressHeading}>
                      {userName}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} className={props.classes.editButton}>
                  </Grid>
                </Grid>
                <Typography variant="body2" color="textSecondary" component="p" className={props.classes.addressText}>
                  {formattedAddress}
                </Typography>
              </CardContentNoPadding>
            </Card>
          </Box>
          : null}
        <Card variant="outlined" style={{ backgroundColor: "#f9ffff" }}>
          <CardContent>
            <Grid container justify='flex-end' style={{padding: 0}}>
              <Grid item style={{padding: 0}}>
                
              </Grid>
            </Grid>
            <Grid container className={props.classes.formDiv}>
              <Grid item xs={12} sm={12}>
                <CustomFormTextField
                  disabled={!orderVerified}
                  size={"small"}
                  value={cardNumber}
                  error={invalidCardDetail}
                  customLabelSuffix={
                    <Box style={{marginLeft: "165px"}}>
                      <img alt="mastercard" src="https://rl-crypto-currency.s3.amazonaws.com/mastercard_symbol.svg" width="30px" height="15px" style={{marginBottom: "3px"}} />
                      <img alt="visa" src="https://rl-crypto-currency.s3.amazonaws.com/visa.svg" width="30px" height="20px"  />
                    </Box>
                  }
                  onChange={handleCardNumberChange}
                  labelText={"Card Number"}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={5} sm={5}>
                <CustomFormTextField
                  value={cardExpiry}
                  error={invalidCardExpiry}
                  disabled={!orderVerified}
                  size={"small"}
                  onChange={handleCardExpiryChange}
                  labelText="Card Expiry"
                  placeholder="MM/YY"
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                />
              </Grid>
              <Grid item xs={7} sm={7}>
                <CustomFormTextField
                  value={cvvCode}
                  error={invalidCvvCode}
                  disabled={!orderVerified}
                  size={"small"}
                  onChange={handleCvvCodeChange}
                  labelText="Card Security Code"
                  placeholder=""
                  tooltipText="Usually a 3 digit code on the back of your card"
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <FormLabel className={props.classes.errorText}>{errorText || ''}</FormLabel>
        {!orderVerified ? <Grid style={{height: "40px"}}></Grid> : null}
        {orderVerified ? <Grid container className={props.classes.customFormButton}>
          <FormControlLabel
            control={<CustomCheckBox checked={checked} onChange={handleCheckBoxChange} name="privacy" />}
            label={
              <Typography className={props.classes.checkBoxLabel}>
                I agree to Routelay's&nbsp;
                <Link href="https://routelay.com/termsofuse.html" target="_blank" rel="noreferrer" className={props.classes.linkText}>
                  Terms of Use
                </Link> and&nbsp;
                <Link href="https://routelay.com/privacy.html" target="_blank" rel="noreferrer" className={props.classes.linkText}>
                  Privacy Policy
                </Link>
              </Typography>
            }
          />
        </Grid> : null}
        <Box>
          {httpCallInProgress ? <CustomLinearProgressBar /> : null}
          <CustomFormButton onClick={handlePaymentSubmit} disabled={(httpCallInProgress || !orderVerified) || !checked} buttonText={`Pay ${formattedFiatAmount}`} />
        </Box>
      </Box >
    </Fade>
  )
}

export default withStyles(styles)(DirectCardCheckout);
