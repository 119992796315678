import React, { useEffect } from 'react';
import CustomFormButton from '../../widgets/CustomFormButton';
import FormHeader from '../../widgets/FormHeader';
import { Grid, Box, FormLabel } from '@material-ui/core';
import CustomLinearProgressBar from 'widgets/CustomLinearProgressBar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import Divider from '@material-ui/core/Divider';
import OrderService from '../../lib/services/OrderService';
import { setOrderId } from '../../actions';
import { useDispatch } from 'react-redux';
import PriceDetail from '../..//widgets/PriceDetail'
import Util from '../../lib/util';
import Fade from '@mui/material/Fade';
import UserService from 'lib/services/UserService';

const styles = theme => ({
  mainDiv: {
    padding: '1.5em',
    paddingTop: '1em',
    width: '100%'
  },
  errorDiv: {
    marginBottom: "0.4em",
    display: "flex",
    justifyContent: "center"
  },
  text: {
    fontSize: '16px',
    fontFamily: 'whitneybook,muli,system-ui,Helvetica,sans-serif',
    color: theme.palette.secondary.main,
  },
  walletText: {
    fontSize: '12px',
    fontFamily: 'whitneybook,muli,system-ui,Helvetica,sans-serif',
    overflowWrap: "break-word",
    wordWrap: "break-word",
    color: theme.palette.secondary.main,
  },
  textGrid: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  textLabelGrid: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  submitButton: {
    marginTop: "0em"
  },
  divider: {
    marginBottom: '10px'
  },
  errorText: {
    fontSize: "12px",
    marginTop: "10px",
    color: "#F5222D",
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif"
  },
  priceDetailDiv: {
    height: '110px',
    marginTop: "15px",
    marginBottom: "27px"
  },
  walletDiv: {
    height: '60px',
    width: "100%",
    marginTop: "10px",
  }
});

function OrderSummary(props) {
  const dispatch = useDispatch();
  const [errorText, setErrorText] = useState("");
  const [disableActionButton, setDisableActionButton] = useState(true);
  const walletAddress = useSelector(state => state.walletAddress);
  const priceDetails = useSelector(state => state.priceDetails);
  const fiatAmount = useSelector(state => state.fiatAmount);
  const selectedCryptoCurrency = useSelector(state => state.selectedCryptoCurrency);
  const selectedCryptoCurrencyId = selectedCryptoCurrency.id;
  const selectedCryptoCurrencyCode = selectedCryptoCurrency.code;
  const selectedFiatCurrency = useSelector(state => state.selectedFiatCurrency);
  const partnerOrderId = useSelector(state => state.partnerOrderId);
  const partnerApiKey = useSelector(state => state.partnerApiKey);
  const disableWalletAddressChange = props.disableWalletAddressChange;
  const [askForHighLimitVerification, setAskForHighLimitVerification] = useState(false);
  const [httpCallInProgress, setHttpCallInProgress] = useState(false);

  useEffect(() => {
    UserService.getTransactionLimits(selectedFiatCurrency.fiat_code)
      .then((response) => {
        if (response?.status === `success`) {
          const limits = response.response;
          if (Number(fiatAmount) > Number(limits.daily) ||
            Number(fiatAmount) > Number(limits.monthly) ||
            Number(fiatAmount) > Number(limits.yearly)) {
            if (response.higherLimit === `APPROVED`) {
              setErrorText("You have reached your maximum buy limit. Please contact team@rampay.io to know more.");
              setDisableActionButton(true);
            } else {
              setErrorText(`You need to complete KYC verification to unlock higher buy limits. Daily limit left: ${selectedFiatCurrency.fiat_symbol}${limits.daily}`);
              setDisableActionButton(false);
              setAskForHighLimitVerification(true);
            }
          } else {
            // do nothing, continue transaction
            setDisableActionButton(false);
          }
          return;
        }
      })
      .catch((e) => {
        // do nothing
        setDisableActionButton(false);
      });
  });

  function createOrder() {
    const body = {
      "order": {
        "cfpmId": selectedFiatCurrency.cfpm_id,
        "cryptoCurrencyId": selectedCryptoCurrencyId,
        "walletAddress": walletAddress,
        "fiatAmount": fiatAmount,
        "cryptoAmount": priceDetails.cryptoCurrencyAmount,
        "cryptoPrice": priceDetails.priceInFiatCurrency,
        "paymentMethodFee": priceDetails.paymentMethodFee,
        "cryptoCurrencyCode": selectedCryptoCurrencyCode
      }
    };

    if (partnerApiKey) {
      body.partnerApiKey = partnerApiKey;
    }

    OrderService.create(body)
      .then((data) => {
        if (data && data.status === `success` && data.order && data.order.id) {
          dispatch(setOrderId(data.order.id));
          if (data.createTransaction) {
            OrderService.createTransaction({ order_id: data.order.id })
              .then((transactionData) => {
                setHttpCallInProgress(false);
                if (transactionData?.status === `success` && transactionData.redirectUrl) {
                  props.history.push('/payment3dsPage', {
                    redirectUrl: transactionData.redirectUrl,
                    fiatPartner: Util.capitalizeFirstLetter(transactionData.fiatPartner) || "Guardarian",
                    showSwapMessage: transactionData.showSwapMessage
                  });
                  return;
                }
              });
          } else {
            setHttpCallInProgress(false);
            props.history.push('/carddetail');
          }
        }
      })
      .catch((err) => {
        setErrorText("We are facing some issue in creating an order for you. Please try again.");
        setHttpCallInProgress(false);
      });
  }

  function updateOrder() {
    const body = {
      "cryptoAmount": priceDetails.cryptoCurrencyAmount,
      "cryptoPrice": priceDetails.priceInFiatCurrency,
      "paymentMethodFee": priceDetails.paymentMethodFee,
      "orderId": partnerOrderId,
    };

    OrderService.updatePartnerOrder(body)
      .then((data) => {
        if (data && data.status === `success` && data.order && data.order.id) {
          dispatch(setOrderId(data.order.id));
          if (data.createTransaction) {
            OrderService.createTransaction({ order_id: data.order.id })
              .then((transactionData) => {
                setHttpCallInProgress(false);
                if (transactionData?.status === `success` && transactionData.redirectUrl) {
                  props.history.push('/payment3dsPage', {
                    redirectUrl: transactionData.redirectUrl,
                    fiatPartner: Util.capitalizeFirstLetter(transactionData.fiatPartner) || "Guardarian",
                    showSwapMessage: transactionData.showSwapMessage
                  });
                  return;
                }
              });
          } else {
            setHttpCallInProgress(false);
            props.history.push('/carddetail');
          }
        }
      })
      .catch((err) => {
        setErrorText("We are facing some issue in creating an order for you. Please try again.");
        setHttpCallInProgress(false);
      });
  }

  function getKycOnboardingURL() {
    UserService.getKycOnboardingUrl()
      .then((data) => {
        if (data && data.status === `success` && data.response?.onboardingUrl) {
          window.open(data.response?.onboardingUrl, '_blank');
          props.history.push('/checkHigherLimitKyc');
        }
      })
      .catch((err) => {
        setErrorText("We are facing some issue in creating an order for you. Please try again.");
        setHttpCallInProgress(false);
      });
  }

  const handleSubmit = () => {
    setErrorText("");
    setHttpCallInProgress(true);
    if (askForHighLimitVerification) {
      getKycOnboardingURL();
      return;
    }

    if (partnerOrderId) {
      updateOrder();
    } else {
      createOrder();
    }
  }

  const handleBackButton = () => {
    if (partnerOrderId && disableWalletAddressChange === "true" && walletAddress) {
      props.history.push("/");
      return;
    }
    props.history.push("/walletform");
  }

  return (
    <Fade in={true}>
      <Box overflow="auto" className={props.classes.mainDiv}>
        <Grid container direction="column" style={{ width: "100%" }}>
          <Grid item>
            <FormHeader titleText="Order Summary" history={props.history} customHandleBackButton={handleBackButton} />
          </Grid>

          <Grid item style={{ marginTop: "50px", height: "100px" }}>
            <Box style={{ display: 'flex', flexDirection: 'column' }}>
              <Grid container>
                <Grid item xs={6} className={props.classes.textLabelGrid}>
                  <Typography component="h4" className={props.classes.text}>You Pay:</Typography>
                </Grid>
                <Grid item xs={2}>

                </Grid>
                <Grid item xs={4} className={props.classes.textGrid}>
                  <Typography component="h4" className={props.classes.text}>{selectedFiatCurrency.fiat_symbol}{fiatAmount.toLocaleString('en')}</Typography>
                </Grid>
              </Grid>
              <Grid container style={{ marginTop: '1em' }}>
                <Grid item xs={6} className={props.classes.textLabelGrid}>
                  <Typography component="h4" className={props.classes.text}>You Get:</Typography>
                </Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={4} className={props.classes.textGrid}>
                  <Typography component="h4" className={props.classes.text}>{priceDetails.cryptoCurrencyAmount.toLocaleString('en')}&nbsp;
                    {selectedCryptoCurrency.code.toUpperCase()}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item className={props.classes.divider}>
            <Divider />
          </Grid>
          <Grid item className={props.classes.priceDetailDiv}>
            <PriceDetail />
          </Grid>
          <Grid item className={props.classes.divider}>
            <Divider />
          </Grid>
          <Grid item className={props.classes.walletDiv}>
            <Grid container>
              <Grid item xs={12} className={props.classes.textLabelGrid}>
                <Typography component="h4" className={props.classes.text}>{Util.capitalizeFirstLetter(selectedCryptoCurrency.name)} Wallet Address:</Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} className={props.classes.textLabelGrid}>
                <Typography component="h4" className={props.classes.walletText}>{walletAddress}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box className={props.classes.submitButton}>
          <Grid container direction="column">
            <Grid item className={props.classes.errorDiv}>
              {errorText ? <FormLabel className={props.classes.errorText}>{errorText}</FormLabel> : ''}
            </Grid>
            <Grid item>
              {httpCallInProgress ? <CustomLinearProgressBar /> : null}
              <CustomFormButton onClick={handleSubmit} disabled={disableActionButton || httpCallInProgress} buttonText={askForHighLimitVerification ? "Verify Now" : "Proceed to Payment"} />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Fade>
  )
}

export default withStyles(styles)(OrderSummary);
