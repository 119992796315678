import ApiConstant from '../ApiConstant';
import Request from '../Request';

class CurrencyService {

	static getFiatCurrencyList() {
		let opts = {
		  method: ApiConstant.fiatCurrencyList.method,
		  url: ApiConstant.fiatCurrencyList.url
		}

		return Request.Fire(opts);
	}

	static getCryptoCurrencyList() {
		let opts = {
		  method: ApiConstant.cryptoCurrencyList.method,
		  url: ApiConstant.cryptoCurrencyList.url
		}

		return Request.Fire(opts);
  }
  
  static getPricingDetails(body) {
		let opts = {
		  method: ApiConstant.pricingDetails.method,
      url: ApiConstant.pricingDetails.url,
      data: body
    }

		return Request.Fire(opts);
	}



}

export default CurrencyService;
