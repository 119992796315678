import lodash from 'lodash';
import { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { Box, FormControl, Avatar, Grid, TextField, Button, FormLabel } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { useSelector, useDispatch } from 'react-redux';
import { setFiatCurrencies, setSelectedFiatCurrency, setFiatAmount, setAllowedFiatPaymentMethods, setSelectedPaymentMethod, setFiatListLoaded, setSelectedFiatCurrencyForSell, setFiatAmountForSell, setPriceParamChanged } from '../actions';
import CurrencyService from '../lib/services/CurrencyService'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import _ from 'lodash';
import { useTheme } from '@material-ui/core/styles';
import CustomTooltip from 'widgets/CustomTooltip';


const styles = theme => ({
  mainDiv: {
    marginBottom: "1em"
  },
  avatar: {
    width: '30px',
    height: '25px',
    marginRight: '0.4em',
  },
  arrowIcon: {
    paddingRight: "0.2em",
  },
  textField: {
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
    "& .MuiInputLabel-outlined": {
      color: theme.palette.secondary.dark
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.secondary.dark,
    },
  },
  errorText: {
    fontSize: "12px",
    height: '2px',
    color: "#F5222D",
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif"
  },
  payViaGrid: {
    display: "flex",
    justifyContent: "flex-end",
  }
});

function FiatSelect(props) {
  const theme = useTheme();
  const history = useHistory();
  const fiatListLoaded = useSelector(state => state.fiatListLoaded);
  const payingCrypto = props.payingCrypto;
  const selectedFiatCurrency = useSelector(state => state.selectedFiatCurrency);
  const selectedFiatCurrencyForSell = useSelector(state => state.selectedFiatCurrencyForSell);
  const fiatAmount = useSelector(state => state.fiatAmount);
  const fiatCurrencyList = useSelector(state => state.fiatCurrencyList);
  const minimumAmountError = useSelector(state => state.minimumAmountError);
  const preferredFromCurrency = props.fromCurrency;
  const preferredFromCurrencyForSell = props.fromCurrencyForSell;
  const disableFiatChange = props.disableFiatChange === "true";
  const disableFiatAmountChange = props.disableFiatAmountChange === "true";
  const preferredDefaultAmount = Number(props.defaultAmount);
  const fiatCurrency = payingCrypto ? selectedFiatCurrencyForSell : selectedFiatCurrency;
  
  const fiatAmountForSell = useSelector(state => state.fiatAmountForSell);
  const dispatch = useDispatch();

  useEffect(() => {
    // isSample key determines that this is coming from a default state from redux and not from backend
    if (fiatCurrencyList && fiatCurrencyList.length && fiatCurrencyList[0] && !fiatCurrencyList[0].isSample) {
      return;
    }
    CurrencyService.getFiatCurrencyList()
      .then((data) => {
        if (data && data.status === `success` && data.response && data.response.length) {
          const fiatCurrencies = data.response;
          let currencySelected = data.response[0];

          if (preferredFromCurrency) {
            const preferredFromCurrencyList = _.filter(fiatCurrencies, (currency) => {
              return currency.fiat_code.toLowerCase() === preferredFromCurrency.toLowerCase();
            });
            if (preferredFromCurrencyList && preferredFromCurrencyList.length) {
              currencySelected = preferredFromCurrencyList[0];
            }
          }

          let defaultFiatCurrencyForSell;

          if (preferredFromCurrencyForSell) {
            defaultFiatCurrencyForSell = lodash.find(fiatCurrencies, (currency) => {
              return currency.sell_allowed === true && currency.code.toLowerCase() === preferredFromCurrencyForSell.toLowerCase();
            });  
          } else {
            defaultFiatCurrencyForSell = lodash.find(fiatCurrencies, (currency) => {
              return currency.sell_allowed === true;
            });
          }

          const defaultAmountFromBackend = Number(currencySelected.fiat_default_amount);
          dispatch(setFiatListLoaded(true));
          dispatch(setFiatCurrencies(fiatCurrencies));
          dispatch(setSelectedFiatCurrency(currencySelected));
          dispatch(setSelectedFiatCurrencyForSell(defaultFiatCurrencyForSell));
          dispatch(setFiatAmount(preferredDefaultAmount > 0 ? preferredDefaultAmount : defaultAmountFromBackend));
          dispatch(setAllowedFiatPaymentMethods(currencySelected.payment_methods));
          dispatch(setSelectedPaymentMethod(currencySelected.payment_methods[0]));
        }
      })
      .catch((err) => {
        console.log(lodash.get(err, 'response.data.error', 'Something went wrong. Please try again later.'))
      })
  }, [dispatch, fiatCurrencyList, props, preferredDefaultAmount, preferredFromCurrency, preferredFromCurrencyForSell]);

  const handleAmountChange = (value) => {
      payingCrypto ? dispatch(setFiatAmountForSell(value)) : dispatch(setFiatAmount(value));
      payingCrypto && dispatch(setPriceParamChanged('FIAT_AMOUNT'));
  };

  const handleClick = () => {
    history.push('/fiatselectionform', { payingCrypto })
  }

  const buttonStyle = {
    height: "100%",
    paddingLeft: "2em",
    paddingRight: "1em",
    color: theme.palette.secondary.dark
  }

  const labelStyle = {
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
    fontSize: "18px",
    color: theme.palette.secondary.dark
  };

  const buttonTextStyle = {
    fontSize: "16px",
    marginRight: "0.2em",
    color: theme.palette.secondary.main
  };
  

  return (
    <Box className={props.classes.mainDiv} style={{visibility: props.visibility || "visible"}}>
      {!fiatListLoaded ? null :
        <Grid container>
          <Grid item xs={12}>
            <FormControl style={formControlStyle}>
              <FormLabel style={labelStyle}>
                <Grid container>
                  <Grid item xs={4}>
                  { payingCrypto ? "You Get" : "You Pay" }
                  </Grid>
                  <Grid item xs={4}>

                  </Grid>
                  <Grid item xs={4}>
                    {payingCrypto ? null : <CustomTooltip title={"Pay via credit/debit card"}>
                      <Grid container className={props.classes.payViaGrid}>
                        <Grid item>
                          <img alt="mastercard" src="https://rl-crypto-currency.s3.amazonaws.com/mastercard_symbol.svg" width="30px" height="25px"/>
                        </Grid>
                        <Grid item>
                          <img alt="visa" src="https://rl-crypto-currency.s3.amazonaws.com/visa.svg" width="35px" height="25px"/>
                        </Grid>
                      </Grid>
                    </CustomTooltip> }
                  </Grid>
                </Grid>
              </FormLabel>
              <TextField
                value={payingCrypto ? (fiatAmountForSell || "") : fiatAmount}
                className={props.classes.textField}
                onChange={(e) => handleAmountChange(e.target.value)}
                variant="outlined"
                disabled={disableFiatAmountChange}
                error={minimumAmountError && minimumAmountError.isError}
                margin="dense"
                inputProps={{ style: { height: "40px", fontSize: "20px", color: theme.palette.secondary.main, fontFamily: "whitneymedium,muli,system-ui,Helvetica,sans-serif" } }}
                InputProps={{
                  style: { paddingRight: "0", borderRadius: "7px" },
                  endAdornment:
                    <Button style={buttonStyle} color="default" onClick={handleClick} disabled={disableFiatChange}>
                      <Avatar className={props.classes.avatar} variant="square" alt="fiat" src={fiatCurrency.country_flag_url} />
                      <span style={buttonTextStyle}>{fiatCurrency.fiat_code}</span>
                      {!disableFiatChange ? <ArrowDropDownIcon className={props.classes.arrowIcon} /> : null}
                    </Button>
                }} />
                <FormLabel className={props.classes.errorText}>
                  {minimumAmountError && minimumAmountError.isError ? `${minimumAmountError.errorText} ${minimumAmountError.minimumAmount ? `: ${fiatCurrency.fiat_symbol}${minimumAmountError.minimumAmount.toLocaleString('en', {maximumFractionDigits: 1})}` : ""}` : ""}
                </FormLabel>
            </FormControl>
          </Grid>
        </Grid>
      }
    </Box>
  )

}

const formControlStyle = {
  width: '100%',
  height: '100%'
}

export default withStyles(styles)(FiatSelect);
