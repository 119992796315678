
const priceDetailsReducer = (state = {"priceInFiatCurrency": 0, "cryptoCurrencyAmount": 0, "paymentMethodFee": 0, "networkFees": 0}, action) => {
  switch (action.type) {
      case 'SET_BUY_CRYPTO_PRICE_DETAILS':
          return action.payload;

      default:
          return state;
  }
}


export default priceDetailsReducer;
