const partnerOrderId = (state = false, action) => {
    switch (action.type) {
        case 'SET_PARTNER_ORDER_ID':
            return action.payload;
  
        default:
            return state;
    }
  }
  
  
  export default partnerOrderId;
  