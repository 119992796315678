import { Box, Grid, FormLabel, Card, IconButton, Typography, CardContent } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import CustomFormButton from 'widgets/CustomFormButton';
import PaymentService from '../../lib/services/PaymentService';
import OrderService from '../../lib/services/OrderService';
import { useSelector } from 'react-redux';
import FormHeader from 'widgets/FormHeader';
import CustomFormTextField from 'widgets/CustomFormTextField';
import CustomLinearProgressBar from 'widgets/CustomLinearProgressBar';
import util from '../../lib/util';
import moment from 'moment';
import EditIcon from '@material-ui/icons/Edit';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import CustomTooltip from 'widgets/CustomTooltip';
import Fade from '@mui/material/Fade';

const styles = theme => ({
  mainGrid: {
    padding: "1.5em",
    paddingTop: '1em',
    width: '100%'
  },
  tooltip: {
    fontSize: "25px"
  },
  errorText: {
    fontSize: "12px",
    marginTop: "20px",
    marginBottom: "20px",
    color: "#F5222D",
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
    display: "flex",
    justifyContent: "center",
    height: '10px'
  },
  formDiv: {
    marginTop: '0.3em',
    marginBottom: '30px'
  },
  customFormButton: {
    // marginTop: '0.3em'
  },
  addressHeading: {
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
    fontSize: "16px",
  },
  addressText: {
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
    fontSize: "14px",
    inlineSize: "300px",
    overflow: true,
    height: '40px'
  },
  editButton: {
    display: "flex",
    justifyContent: "flex-end"
  },
  payViaText: {
    color: theme.palette.secondary.dark,
    marginRight: "1em",
    marginTop: "10px",
    fontSize: "14px"
  },
  payViaGrid: {
    marginTop: "0.6em",
  }
});

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      format="##/##"
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  // name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

function CardDetail(props) {
  const [cardNumber, setCardNumber] = useState("");
  const [cardExpiry, setCardExpiry] = useState("");
  const [cvvCode, setCvvCode] = useState("");
  const [invalidCardDetail, setInvalidCardDetail] = useState(false)
  const [invalidCardExpiry, setInvalidCardExpiry] = useState(false)
  const [invalidCvvCode, setInvalidCvvCode] = useState(false)
  const [errorText, setErrorText] = useState("");
  const [httpCallInProgress, setHttpCallInProgress] = useState(false);
  const orderId = useSelector(state => state.orderId);
  const userAddress = useSelector(state => state.userAddress);
  const formattedAddress = util.generateFormattedAddress(userAddress);

  const handleCardNumberChange = (event) => {
    setCardNumber(event.target.value);
  };

  const handleCardExpiryChange = (event) => {
    setCardExpiry(event.target.value);
  };

  const handleCvvCodeChange = (event) => {
    setCvvCode(event.target.value);
  };

  const getValidationError = (params) => {
    let validForm = true;
    if (!cardNumber) {
      setInvalidCardDetail(true);
      validForm = false;
    } else {
      setInvalidCardDetail(false);
    }
    if (!cardExpiry || !moment(cardExpiry, 'MM/YY').isValid()) {
      setInvalidCardExpiry(true);
      validForm = false;
    } else {
      setInvalidCardExpiry(false);
    }
    if (!cvvCode) {
      setInvalidCvvCode(true);
      validForm = false;
    } else {
      setInvalidCvvCode(false);
    }
    return validForm;
  }

  const handleEditAddressClick = () => {
    props.history.push('/addressform', { callingPage: "CARD_DETAIL" });
  }

  const handleSubmit = (event) => {
    setErrorText("");
    const body = {
      payment_method: {
        number: cardNumber,
        year: "20" + cardExpiry.substring(2, 4),
        month: cardExpiry.substring(0, 2),
        cvv: cvvCode
      },
      order_id: String(orderId)
    };
    const isValidForm = getValidationError(body);
    if (!isValidForm) {
      return;
    }
    setHttpCallInProgress(true);

    PaymentService.createPayment(body)
      .then((data) => {
        setHttpCallInProgress(false);
        if (data) {
          if (data && data.auth_response) {
            const smsNeeded = lodash.get(data, 'auth_response.smsNeeded', false);
            const card2faNeeded = lodash.get(data, 'auth_response.card2faNeeded', false);
            const authorization3dsUrl = lodash.get(data, 'auth_response.authorization3dsUrl', "");
            const showSwapMessage = lodash.get(data, 'showSwapMessage', true);

            if (!smsNeeded && !card2faNeeded && authorization3dsUrl) {
              props.history.push('/payment3dsPage', {
                redirectUrl: authorization3dsUrl,
                fiatPartner: "Wyre",
                showSwapMessage: showSwapMessage
              });
              return;
            }

            if (!smsNeeded && !card2faNeeded) {
              props.history.push('/checkpayment');
              return;
            }

            props.history.push('/authorizepayment', {
              smsNeeded,
              card2faNeeded,
            });
          } else {
            setErrorText("Looks like we are facing some issue with our banking partners. Please try again later.");
          }
        }
      })
      .catch((err) => {
        const errorMessage = lodash.get(err, 'response.data.message', null);
        if (errorMessage.startsWith(`You reached your daily limit`)) {
          OrderService.createTransaction({ order_id: orderId })
            .then((transactionData) => {
              setHttpCallInProgress(false);
              if (transactionData?.status === `success` && transactionData.redirectUrl) {
                props.history.push('/payment3dsPage', {
                  redirectUrl: transactionData.redirectUrl,
                  fiatPartner: util.capitalizeFirstLetter(transactionData.fiatPartner) || "Guardarian",
                  showSwapMessage: transactionData.showSwapMessage
                });
                return;
              } else {
                setErrorText(errorMessage || "Something went wrong. Please try again later.");
              }
            })
            .catch((e) => {
              setHttpCallInProgress(false);
              setErrorText(errorMessage || "Something went wrong. Please try again later.");
            })
        } else {
          setHttpCallInProgress(false);
          setErrorText(errorMessage || "Something went wrong. Please try again later.");
        }
      })
  }

  return (
    <Fade in={true}>
      <Box overflow="auto" className={props.classes.mainGrid}>
        <Grid container direction="column">
          <Grid item>
            <FormHeader titleText="Debit/Credit card details" history={props.history} />
          </Grid>
          <Grid item style={{ height: "20px" }}></Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} sm={12}>
            <Card>
              <CardContent>
                <Grid container>
                  <Grid item xs={6} sm={6}>
                    <Typography variant="body2" className={props.classes.addressHeading}>
                      Billing Address
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} className={props.classes.editButton}>
                    <CustomTooltip title={"Edit"}>
                      <IconButton size="small" onClick={handleEditAddressClick}>
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </CustomTooltip>
                  </Grid>
                </Grid>
                <Typography variant="body2" color="textSecondary" component="p" className={props.classes.addressText}>
                  {formattedAddress}
                </Typography>

              </CardContent>
              {/* <CardActions>
              <Button size="small" color="primary" onClick={handleEditAddressClick}>
                Edit
              </Button>
            </CardActions> */}
            </Card>
          </Grid>
        </Grid>
        <Grid container className={props.classes.payViaGrid}>
          <Grid item className={props.classes.payViaText}>
            Pay using
          </Grid>
          <Grid item>
            <img alt="mastercard" src="https://rl-crypto-currency.s3.amazonaws.com/mastercard_symbol.svg" width="40px" height="35px" style={{ marginBottom: "0.2em" }} />
            <img alt="visa" src="https://rl-crypto-currency.s3.amazonaws.com/visa.svg" width="70px" height="40px" />
          </Grid>
        </Grid>
        <Grid container className={props.classes.formDiv}>
          <Grid item xs={12} sm={12}>
            <CustomFormTextField
              value={cardNumber}
              error={invalidCardDetail}
              onChange={handleCardNumberChange}
              labelText="Card Number"
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={5} sm={5}>
            <CustomFormTextField
              value={cardExpiry}
              error={invalidCardExpiry}
              onChange={handleCardExpiryChange}
              labelText="Card Expiry"
              placeholder="MM/YY"
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
            />
          </Grid>
          <Grid item xs={7} sm={7}>
            <CustomFormTextField
              value={cvvCode}
              error={invalidCvvCode}
              onChange={handleCvvCodeChange}
              labelText="Card Security Code"
              placeholder=""
              tooltipText="Usually a 3 digit code on the back of your card"
            />
          </Grid>
        </Grid>
        <Grid container className={props.classes.customFormButton}></Grid>
        <FormLabel className={props.classes.errorText}>{errorText ? errorText : ''}</FormLabel>
        <Box>
          {httpCallInProgress ? <CustomLinearProgressBar /> : null}
          <CustomFormButton onClick={handleSubmit} disabled={httpCallInProgress} buttonText="Submit" />
        </Box>
      </Box >
    </Fade>
  )
}

export default withStyles(styles)(CardDetail);
