import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import OrderService from 'lib/services/OrderService';
import lodash from 'lodash';
import paymentCheckAnimation from 'assets/animations/payment_check.json';
import CustomFormButton from 'widgets/CustomFormButton';
import CustomLottie from 'widgets/CustomLottie';
import ApiConstant from 'lib/ApiConstant';
// import useMediaQuery from '@material-ui/core/useMediaQuery';

const styles = theme => ({
  header: {
    display: 'flex',
    height: '70px',
    backgroundColor: '#fbfcf8',
    marginLeft: '-1.5em',
    marginRight: '-1.5em',
    marginTop: '-1em',
  },
  text: {
    fontSize: 13
  },
  mainGrid: {
    display: "flex",
    flexDirection: 'column',
    justifyContent: "center",
    alignItems: "center",
    padding: "2em",
  },
  mainText: {
    display: "flex",
    fontSize: "14px",
    justifyContent: "center",
    color: theme.palette.secondary.main
  },
  lottie: {
    display: "flex",
    justifyContent: "center",
    alignItems: 'center',
    textAlign: "center",
  },
  actionButton: {
    marginTop: "10px"
  },
  cancelActionGrid: {
    marginTop: "20px"
  },
  cancelAction: {
    fontSize: "14px",
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
    color: theme.palette.secondary.main,
    textDecoration: 'underline',
    cursor: "pointer"
  },
  mainTextListItem: {
    marginBottom: "20px"
  }
});

function Payment3dsPage(props) {
  const redirectUrl = lodash.get(props, 'location.state.redirectUrl');
  const fiatPartner = lodash.get(props, 'location.state.fiatPartner');
  const showSwapMessage = lodash.get(props, 'location.state.showSwapMessage');
  const orderId = useSelector(state => state.orderId);
  // const isSmallDevice = useMediaQuery('(max-width:480px)', { noSsr: true });
  let authroziationWindow;
  const selectedCryptoCurrency = useSelector(state => state.selectedCryptoCurrency);

  const closeWindow = (windowToClose) => {
    try {
      windowToClose.close();
    } catch (e) {
      console.log(`Exception in closing window`, e);
    }
  }

  // if (isSmallDevice) {
  //   if (!authroziationWindow) {
  //     authroziationWindow = window.open(redirectUrl, '_blank').focus();
  //   }
  // } else {
  //   if (!authroziationWindow) {
  //     authroziationWindow = window.open(redirectUrl, 'popup', 'width=600, height=600');
  //   }
  // }

  useEffect(() => {
    let intervalId = setInterval(function checkStatusIfTabInFocus() {
      if (document.visibilityState === `visible`) {
        OrderService.getOrderById(orderId)
          .then((response) => {
            const orderStatus = lodash.get(response, 'status');
            if ([`COMPLETED`, `FAILED`, `PROCESSING_CRYPTO`, `RELEASING_CRYPTO`].indexOf(orderStatus) > -1) {
              closeWindow(authroziationWindow);
              props.history.push('/checkpayment');
              clearInterval(intervalId);
              return;
            }
          })
          .catch((e) => {
            console.log(`Error in fetching order status`);
            clearInterval(intervalId);
          });
      }
    }, ApiConstant.getOrder.pollingIntervalInMs);
  });

  const handleCancelTransactionClick = () => {
    closeWindow(authroziationWindow);
    props.history.replace("/orderlist", { callingPage: "PAYMENT_3DS_PAGE" });
  };

  const handleAuthorizePaymentClick = () => {
    window.open(redirectUrl, "_blank");
  }

  return (
    <Box style={mainDivStyle}>
      <Grid container direction="column" className={props.classes.mainGrid}>
        <Grid item className={props.classes.lottie}>
          <CustomLottie
            lottie={paymentCheckAnimation}
            loop={true}
            width={100}
            hieght={100}
          />
        </Grid>
        <Grid item>
          <span className={props.classes.mainText}>
            <ul>
              <li className={props.classes.mainTextListItem}>To continue, click on <b>Authorise Transaction</b> below, which will open up a window for our fiat partner {fiatPartner}, to complete the transaction.</li>
              {showSwapMessage ? <li className={props.classes.mainTextListItem}>Do not worry if you see the destination address of a different currency like Algorand, USDT in the next page, we will swap that currency with <b>{selectedCryptoCurrency?.code || " your currency "}</b> in your wallet.</li> : null}
              <li className={props.classes.mainTextListItem}>Stay on this screen after the transaction is done, we will take you to your order status after the payment is done.</li>
            </ul>
          </span>
        </Grid>
        <Grid item className={props.classes.actionButton}>
          <CustomFormButton onClick={handleAuthorizePaymentClick} buttonText="Authorise Transaction" />
        </Grid>
        <Grid item className={props.classes.cancelActionGrid}>
          <Typography onClick={handleCancelTransactionClick} className={props.classes.cancelAction}>
            Cancel Transaction
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );

}

const mainDivStyle = {
  padding: '1.5em',
  paddingTop: '1em',
}

export default withStyles(styles)(Payment3dsPage);
