const allowedFiatPaymentMethodsReducer = (state = [{id: 1, name: 'UPI', image: 'https://cdn.icon-icons.com/icons2/2699/PNG/512/upi_logo_icon_170312.png'}], action) => {
  switch (action.type) {
    case 'SET_ALLOWED_FIAT_PAYMENT_METHODS':
      return action.payload;

    default:
      return state;
  }
}

export default allowedFiatPaymentMethodsReducer;
