import React from 'react'
import Typography from '@material-ui/core/Typography'
import { Grid, Box, Divider } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  mainGrid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    marginTop: '8px',
  },
  mainBox: {
  },
  text: {
    fontFamily: 'whitneymedium,whitneybook,muli,system-ui,Helvetica,sans-serif',
    fontSize: '12px',
    color: 'grey'
  },
});

function Footer(props) {
  let footerText = ``;

  return (
    <Box className={props.classes.mainBox}>
      <Grid container direction="column">
      <Grid container>
        <Divider />
      </Grid>
        <Grid container>
        <Grid item xs={2} sm={2}>
        </Grid>
        <Grid item xs={8} sm={8} className={props.classes.mainGrid}>
          <Typography component="h4" className={props.classes.text}>{footerText}</Typography>
        </Grid>
        <Grid item xs={2} sm={2}>
        </Grid>
        </Grid>
        
      </Grid>
    </Box>
  )
}

export default withStyles(styles)(Footer);
