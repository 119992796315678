import { Box, Grid, FormLabel, Card, IconButton, Typography, CardContent, Select, MenuItem } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import CustomFormButton from 'widgets/CustomFormButton';
import PaymentService from '../../lib/services/PaymentService';
import { useSelector } from 'react-redux';
import FormHeader from 'widgets/FormHeader';
import CustomFormTextField from 'widgets/CustomFormTextField';
import CustomLinearProgressBar from 'widgets/CustomLinearProgressBar';
import util from '../../lib/util';
import EditIcon from '@material-ui/icons/Edit';
import lodash from 'lodash';
import CustomTooltip from 'widgets/CustomTooltip';
import Fade from '@mui/material/Fade';
import { styled } from "@mui/material/styles";


const styles = theme => ({
  mainGrid: {
    padding: "1.5em",
    paddingTop: '1em',
    width: '100%'
  },
  tooltip: {
    fontSize: "25px"
  },
  errorText: {
    fontSize: "12px",
    marginTop: "20px",
    marginBottom: "20px",
    color: "#F5222D",
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
    display: "flex",
    justifyContent: "center",
    height: '10px'
  },
  formDiv: {
    marginTop: '0.3em',
    marginBottom: '10px'
  },
  label: {
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
    color: theme.palette.secondary.dark
  },
  customFormButton: {
    // marginTop: '0.3em'
  },
  addressHeading: {
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
    fontSize: "16px",
  },
  addressText: {
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
    fontSize: "14px",
    inlineSize: "300px",
    overflow: true,
    height: '40px'
  },
  editButton: {
    display: "flex",
    justifyContent: "flex-end"
  },
  payViaText: {
    color: theme.palette.secondary.dark,
    marginRight: "1em",
    marginTop: "10px",
    fontSize: "14px"
  },
  payViaGrid: {
    marginTop: "0.6em",
  }
});

function ConnectBankAccount(props) {
  const [accountNumber, setAccountNumber] = useState("");
  const [routingNumber, setRoutingNumber] = useState("");
  const [accountType, setAccountType] = useState("SAVINGS");
  const [invalidAccountNumber, setInvalidAccountNumber] = useState(false)
  const [invalidRoutingNumber, setInvalidRoutingNumber] = useState(false)
  const [errorText, setErrorText] = useState("");
  const [httpCallInProgress, setHttpCallInProgress] = useState(false);
  const userAddress = useSelector(state => state.userAddress);
  const userProfile = useSelector(state => state.userProfile);
  const formattedAddress = util.generateFormattedAddress(userAddress);
  const formattedUserDetails = util.generateFormattedUserDetails(userProfile);
  const theme = useTheme();

  const handleAccountNumberChange = (event) => {
    setAccountNumber(event.target.value);
  };

  const handleRoutingNumberChange = (event) => {
    setRoutingNumber(event.target.value);
  };

  const handleAccountTypeChange = (event) => {
    setAccountType(event.target.value);
  };

  const getValidationError = (params) => {
    let validForm = true;
    setErrorText("");
    if (!accountNumber || accountNumber.length < 5 || accountNumber.length > 20) {
      setInvalidAccountNumber(true);
      setErrorText("Please enter a valid account number");
      validForm = false;
      return validForm;
    } else {
      setInvalidAccountNumber(false);
    }
    if (!routingNumber || routingNumber.length !== 9) {
      setErrorText("Please enter a valid routing number");
      setInvalidRoutingNumber(true);
      validForm = false;
      return validForm;
    } else {
      setInvalidRoutingNumber(false);
    }
    if (["CHECKING", "SAVINGS"].indexOf(accountType) === -1) {
      setErrorText("Account type is not valid");
      validForm = false;
      return validForm;
    }

    return validForm;
  }

  const handleEditAddressClick = () => {
    props.history.push('/addressform', { callingPage: "CONNECT_BANK_ACCOUNT" });
  }

  const handleEditName = () => {
    props.history.push('/userprofile', { callingPage: "CONNECT_BANK_ACCOUNT" });
  }


  const handleSubmit = (event) => {
    setErrorText("");
    const body = {
      bankDetails: {
        accountNumber,
        routingNumber,
        accountType,
      },
    };
    const isValidForm = getValidationError(body);
    if (!isValidForm) {
      return;
    }
    setHttpCallInProgress(true);

    PaymentService.addBankAccount(body)
      .then((data) => {
        setHttpCallInProgress(false);
        if (data && data.success) {
          props.history.push("/ordersummaryforsell", {paymentMethodName: data.paymentMethodName, paymentMethodId: data.paymentMethodId,});
        } else {
          setErrorText("Looks like we are facing some issue with our banking partners. Please try again later.");
        }
      })
      .catch((err) => {
        const errorMessage = lodash.get(err, 'response.data.message', null);
        setHttpCallInProgress(false);
        setErrorText(errorMessage || "Something went wrong. Please try again later.");
      })
  }

  const handleBackButton = () => {
    props.history.replace("/sell");
  }

  const CardContentNoPadding = styled(CardContent)(`
    &:last-child {
      padding-bottom: 0;
    }
  `);

  return (
    <Fade in={true}>
      <Box overflow="auto" className={props.classes.mainGrid}>
        <Grid container direction="column">
          <Grid item>
            <FormHeader titleText="Add a Bank Account" history={props.history} customHandleBackButton={handleBackButton} />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} sm={12}>
            <Card variant='outlined'>
              <CardContentNoPadding>
                <Grid container>
                  <Grid item xs={6} sm={6}>
                    <Typography variant="body2" className={props.classes.addressHeading}>
                      Name on bank account
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} className={props.classes.editButton}>
                    <CustomTooltip title={"Edit"}>
                      <IconButton size="small" onClick={handleEditName}>
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </CustomTooltip>
                  </Grid>
                </Grid>
                <Typography variant="body2" color="textSecondary" component="p" className={props.classes.addressText}>
                  {formattedUserDetails}
                </Typography>
              </CardContentNoPadding>
            </Card>
            <Grid style={{ height: "6px" }}>
            </Grid>
            <Card variant='outlined'>
              <CardContent>
                <Grid container>
                  <Grid item xs={6} sm={6}>
                    <Typography variant="body2" className={props.classes.addressHeading}>
                      Beneficiary Address
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} className={props.classes.editButton}>
                    <CustomTooltip title={"Edit"}>
                      <IconButton size="small" onClick={handleEditAddressClick}>
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </CustomTooltip>
                  </Grid>
                </Grid>
                <Typography variant="body2" color="textSecondary" component="p" className={props.classes.addressText}>
                  {formattedAddress}
                </Typography>

              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid style={{ height: "6px" }}>
        </Grid>
        <Grid container className={props.classes.formDiv}>
          <Grid item xs={12} sm={12}>
            <CustomFormTextField
              value={accountNumber}
              error={invalidAccountNumber}
              onChange={handleAccountNumberChange}
              labelText="Account Number"
              placeholder="1234567890"
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={7} sm={7}>
            <CustomFormTextField
              value={routingNumber}
              error={invalidRoutingNumber}
              onChange={handleRoutingNumberChange}
              labelText="Routing Number"
              placeholder="123456789"
            />
          </Grid>
          <Grid item xs={5} sm={5}>
            <FormLabel className={props.classes.label}>Account Type</FormLabel>
            <Select
              className={props.select}
              value={accountType}
              onChange={handleAccountTypeChange}
              height="50%"
              color="secondary"
              style={{
                width: "100%",
                height: "70%",
                color: theme.palette.secondary.dark,
                borderColor: theme.palette.secondary.dark
              }}
              variant="outlined"
            >
              <MenuItem key={1} value={"SAVINGS"}>
                <Box className={props.classes.menuItem}>
                  <span className={props.classes.isdCodeSelect}> {"SAVINGS"} </span>
                </Box>
              </MenuItem>
              <MenuItem key={2} value={"CHECKING"}>
                <Box className={props.classes.menuItem}>
                  <span className={props.classes.isdCodeSelect}> {"CHECKING"} </span>
                </Box>
              </MenuItem>
            </Select>
          </Grid>
        </Grid>
        <Grid container className={props.classes.customFormButton}></Grid>
        <FormLabel className={props.classes.errorText}>{errorText}</FormLabel>
        <Box>
          {httpCallInProgress ? <CustomLinearProgressBar /> : null}
          <CustomFormButton onClick={handleSubmit} disabled={httpCallInProgress} buttonText="Submit" />
        </Box>
      </Box >
    </Fade>
  )
}

export default withStyles(styles)(ConnectBankAccount);
