const primaryColorReducer = (state = "", action) => {
  switch (action.type) {
      case 'SET_PRIMARY_COLOR':
          return action.payload;

      default:
          return state;
  }
}


export default primaryColorReducer;
