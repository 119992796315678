const orderIdReducer = (state = 0, action) => {
  switch (action.type) {
      case 'SET_ORDER_ID':
          return action.payload;

      default:
          return state;
  }
}


export default orderIdReducer;
