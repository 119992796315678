import { useEffect, useState } from 'react';
import { init, tearDown } from 'onfido-sdk-ui';
import { Box, FormLabel } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import KycService from 'lib/services/KycService';
import lodash from 'lodash';
import  './onfido.css';


const styles = theme => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '2em'
  },
  CustomFormButton: {
    marginTop: '12em'
  },
  kycDiv: {
    width: '100%',
    height: '100%'
  },
  mainDiv: {
    width: '100%'
  }
});

function KycForm(props) {

  const [errorText, setErrorText] = useState("");

  useEffect(() => {
    KycService.generateSdkToken()
      .then((response) => {
        if (response && response.status === `success` && lodash.get(response, 'response.sdkToken')) {
          init({
            containerId: "onfido-mount",
            token: lodash.get(response, 'response.sdkToken'),
            onComplete: (data) => {
              KycService.submitKycDocuments(JSON.parse(JSON.stringify(data)))
              .then((result) => {
                try {
                  if (result && result.status === `success` && Number(lodash.get(result, 'response.checkId', null))) {
                    props.history.push('/checkkyc', {checkId: Number(result.response.checkId)});
                    tearDown();
                  } else {
                    props.history.push('/checkkyc', {checkId: Number(-1)});
                    tearDown();
                    // setErrorText("Looks like we are facing some issue with our KYC partner service. We are working on resolving it immediately. Please try again later or contact us at team@rampay.io");
                  }
                } catch(e) {
                  // do nothing
                }
              })
              .catch((e) => {
                props.history.push('/checkkyc', {checkId: Number(-1)});
                tearDown();
                // setErrorText("Looks like we are facing some issue with our KYC partner service. We are working on resolving it immediately. Please try again later or contact us at team@rampay.io");
              })
            },
            userDetails: {
              smsNumber: ''
            },
            steps: [
              'document',
              'face',
            ]
          });
        }

      })
      .catch((e) => {
        setErrorText("Looks like we are facing some issue with our KYC partner service. We are working on resolving it immediately. Please try again later or contact us at team@rampay.io");
      });
  });

  return (
    <Box className={props.classes.mainDiv}>
      <Box style={{ fontSize: 'small', marginTop: "auto", textAlign: "center" }}>
        <FormLabel style={{ fontSize: 'small', marginTop: "3em" }}>{errorText}</FormLabel>
      </Box>
      <div id="onfido-mount" className={props.classes.kycDiv}></div>
    </Box>
  );

}

export default withStyles(styles)(KycForm);