const selectedFiatCurrencyForSellReducer = (state = {}, action) => {
  switch (action.type) {
      case 'SET_SELECTED_FIAT_CURRENCY_FOR_SELL':
          return action.payload;
      default:
          return state;
  }
}

export default selectedFiatCurrencyForSellReducer;
