const userProfile = (state = {firstName: "", lastName: "", isdCode: "", phoneNumber: "", dateOfBirth: ""}, action) => {
  switch (action.type) {
      case 'SET_USER_PROFILE':
          return action.payload;

      default:
          return state;
  }
}

export default userProfile;
