import { Box, Grid, FormLabel, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import CustomTooltip from './CustomTooltip';
import { useTheme } from '@material-ui/core/styles';

const styles = theme => ({
  label: {
    fontFamily: "whitneybook,muli,system-ui,Helvetica,sans-serif",
    color: theme.palette.secondary.dark
  },
  tooltip: {
    fontSize: "15px"
  },
  textField: {
    "& .MuiInputLabel-outlined": {
      color: theme.palette.secondary.dark
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.secondary.dark,
    },
  }
});

function CustomFormTextField(props) {
  let inputProps = props.InputProps || {};
  const theme = useTheme();
  inputProps.classes = {input: props.classes.label};

  return (
    <Box>
      <Grid container>
        <Grid item>
          <Grid container>
            <Grid item xs={12} sm={12}>
              <FormLabel className={props.classes.label}>{props.labelText}</FormLabel>
            </Grid>
          </Grid>
        </Grid>
        <Grid item >
          {props.tooltipText ? <CustomTooltip title={props.tooltipText} className={props.classes.tooltip} placement="top-end">
            <InfoRoundedIcon size="small" style={{ fontSize: "16px", color: theme.palette.secondary.dark }}/>
          </CustomTooltip> : ""}
                {props.customLabelSuffix || ""}
        </Grid>
      </Grid>
      <Grid container>
        <TextField
          value={props.value}
          size={props.size || "medium"}
          error={props.error}
          disabled={props.disabled}
          onChange={props.onChange}
          placeholder={props.placeholder}
          className={props.classes.textField}
          style={{
            width: props.width ? props.width : "100%",
          }}
          label=""
          variant="outlined"
          helperText={props.error ? props.helperText : ""}
          InputProps={inputProps}
        />
      </Grid>
    </Box>
  );

}

export default withStyles(styles)(CustomFormTextField);
