
const minimumAmountErrorReducer = (state = { isError: false, errorText: "" }, action) => {
  switch (action.type) {
    case 'SET_MINIMUM_AMOUNT_ERROR':
      return action.payload;

    default:
      return state;
  }
}


export default minimumAmountErrorReducer;
