class Util {

  static capitalizeFirstLetter(string) {
    if (!string) {
      return '';
    }

    return string = string.charAt(0).toUpperCase() + string.slice(1);
  }

  static isValidEmailAddress(emailAddress) {
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(String(emailAddress).toLowerCase());
  }

  static isValidHexCode(hexcode) {
    const hexcodeRegex = /^[A-Fa-f0-9]{6}$/;
    return hexcodeRegex.test(hexcode);
  }

  static isValidCryptoAddress(address, cryptoCurrency) {
    if (!address || !cryptoCurrency) {
      return false;
    }

    try {
      return window.WAValidator.validate(address, cryptoCurrency);
    } catch (e) {
      // Incase the cryptoCurrency is unknown for this validator library, it throws an exception. We cant really validate user input then hence returning true.
      return true;
    }

  }

  static isValidVerificationCode(code) {
    const codeRegex =
      /^[0-9]{6}$/;
    return codeRegex.test(String(code));
  }

  static generateFormattedAddress(address) {
    const formattedAddress = [];
    if (address && Object.keys(address).length &&  address.addressLine1) {
      formattedAddress.push(address.addressLine1);
      if (address.addressLine2) {
        formattedAddress.push(address.addressLine2);
      }
      formattedAddress.push(address.city);
      formattedAddress.push(address.state);
      formattedAddress.push(address.country);
      formattedAddress.push(address.postalCode);
    }
    return formattedAddress.join(", ");
  }

  static generateFormattedUserDetails(user) {
    let formattedUserInfo = ``;
    if (user.firstName && user.lastName) {
      formattedUserInfo = `${user.firstName} ${user.lastName}`
    }
    return formattedUserInfo;
  }

}

export default Util;
